import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputSwitch} from "primereact/inputswitch";
import {classNames} from "primereact/utils";
import {Dropdown} from "primereact/dropdown";
import {Form, Field} from 'react-final-form';
import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import currencyFormatter from "currency-formatter";
import {Link} from "react-router-dom";

import SubscriptionListService from "../services/subscription/SubscriptionListService";
import SubscriptionAddService from "../services/subscription/SubscriptionAddService";
import SubscriptionStartPayService from "../services/subscription/SubscriptionStartPayService";
import SubscriptionCancelService from "../services/subscription/SubscriptionCancelService";
import SubscriptionPaymentStatusService from "../services/subscription/SubscriptionPaymentStatusService";
import NetworkSubscriptionRefreshService from "../services/network/NetworkSubscriptionRefreshService";

export const SubscriptionPage = (props) => {

  const dt = useRef(null);
  const [expandedRows, setExpandedRows] = useState(null);

  const [view, setView] = useState('subscription');
  const [yearlyPrices, setYearlyPrices] = useState(false);
  const [tot, setTot] = useState({
    totYearly: 0,
    totMonthly: 0,
  });
  const [modules, setModules] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState({});
  const [paymentStatusMessage, setPaymentStatusMessage] = useState('');
  const [paymentTrId, setPaymentTrId] = useState('');
  const [paymentPrepare, setPaymentPrepare] = useState({
    invoiceCompanyAddressCountryCode: "HU",
    paymentMethod: "simplepay"
  });

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptDatatrans, setAcceptDatatrans] = useState(false);
  const [acceptRecurring, setAcceptRecurring] = useState(false);

  const paymentPrepareValidate = (data) => {
    let errors = {};
    // if (!data.paymentMethod) {
    //   errors.paymentMethod = 'Fizetési mód választása kötelező';
    // }
    // if (!data.invoiceCompany) {
    //   errors.invoiceCompany = 'Kötelező mező';
    // }
    // if (!data.invoiceCompanyVatNumber) {
    //   errors.invoiceCompanyVatNumber = 'Kötelező mező';
    // }
    // if (!data.invoiceCompanyAddressCity) {
    //   errors.invoiceCompanyAddressCity = 'Kötelező mező';
    // }
    // if (!data.invoiceCompanyAddressZip) {
    //   errors.invoiceCompanyAddressZip = 'Kötelező mező';
    // }
    // if (!data.invoiceCompanyAddressDetail) {
    //   errors.invoiceCompanyAddressDetail = 'Kötelező mező';
    // }
    return errors;
  };
  const paymentPrepareOnSubmit = (data, form) => {
    setLoading(true)
    setPaymentPrepare({
      ...paymentPrepare,
      data
    });
    if (data.paymentMethod === "simplepay") {
      SubscriptionStartPayService(data)
        .then(response => {
          window.location.replace(response.data.url);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      setLoading(false)
      SubscriptionStartPayService(data)
        .then(response => {
          setView('payment_transfer')
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    setTimeout(function () {
      let totYearly = 0
      let totMonthly = 0
      selectedModules.map((module, index) => {
        if (module.monthlyFee) {
          totMonthly = totMonthly + module.monthlyFee
        }
        if (module.yearlyFee) {
          totYearly = totYearly + module.yearlyFee
        }
      })
      setTot({
        totYearly: totYearly,
        totMonthly: totMonthly
      })
    }, 1000)
  }, [selectedModules]);

  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div className="p-ml-5 p-pl-5">
          {data.features.map((feature, index) => {
            return (
              <>
                <h4 className={"p-m-0 p-p-0"}>{feature.name}</h4>
                <small className={"p-d-block p-text-secondary p-mt-2"}>{feature.description}</small>
                {index + 1 !== data.features.length &&
                <hr className={"p-d-block p-my-3"}/>
                }
              </>
            )
          })}
        </div>
      </>
    );
  }

  useEffect(() => {
    document.title = 'Előfizetési csomagok' + ' - ' + process.env.REACT_APP_TITLE;
    if (document.getElementsByClassName('activeMenu')[0]) {
      document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
    }

    if (window.App.urlVars().r) {
      SubscriptionPaymentStatusService(window.location.hash.replace('#/subscriptions', ''))
        .then(response => {
          NetworkSubscriptionRefreshService()
            .then(response => {
              localStorage.setItem("roles", JSON.stringify(response.data).toLowerCase())
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          setView('payment_return')
          setPaymentStatus(response.data.status)
          setPaymentStatusMessage(response.data.message)
          setPaymentTrId(response.data.transactionId)
          //window.App.toastShow('success', 'Sikeres fizetés!', response.data.message);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      // setView('payment_return')
      // let statusBase64 = window.App.urlVars().r
      // let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      // if (base64regex.test(statusBase64)) {
      //   let status = atob(statusBase64)
      //   let statusJson = JSON.parse(status)
      //   setPaymentStatus(statusJson)
      // } else {
      //   setPaymentStatus({
      //     e: 'CANCEL'
      //   })
      // }
    }

    SubscriptionListService()
      .then(response => {
        setSubscriptionStatus(response.data.status.toLowerCase())
        let prices = []
        let modules = []
        response.data.modules.map(function (module, index) {
          let monthlyFee
          let yearlyFee
          if (module.fees && module.fees.monthly && module.fees.monthly.price) {
            monthlyFee = module.fees.monthly.price
          }
          if (module.fees && module.fees.yearly && module.fees.yearly.price) {
            yearlyFee = module.fees.yearly.price
          }
          if (module.code == 'finance' || module.code == 'order') {
            modules.push({
              code: module.code,
              module: module.name,
              description: module.description,
              currency: "HUF",
              monthlyFee: monthlyFee,
              yearlyFee: yearlyFee,
              expiresAt: module.expiresAt,
              features: module.features
            })
            if (module.expiresAt && module.expiresAt !== null) {
              selectedModules.push({
                code: module.code,
                module: module.name,
                description: module.description,
                currency: "HUF",
                monthlyFee: monthlyFee,
                yearlyFee: yearlyFee,
                expiresAt: module.expiresAt,
              })
            }
          }
        })
        setModules(modules)
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
  }, []);

  return (
    <div className="DashboardPage SubscriptionPage p-component p-p-3">
      <div className={"p-grid p-d-flex p-flex-wrap"}>
        <div className={"p-col-12 p-p-5 left"}>
          <div className={"p-p-5"} style={{maxWidth: '1100px', marginLeft: 'auto', marginRight: 'auto'}}>
            <h2 className={"p-p-0 p-mt-0 p-mb-0 text-primary p-text-center"}>
              Előfizetés és licenszek
            </h2>
            <p className={"p-p-0 p-m-0 p-text-center"}>Válaszd ki a számodra legjobb ajánlatunkat</p>
            <h3 className={"p-text-center w-75 p-mb-5"}>
              Számlázás és bizonylatolás, Pénzügy, CRM, Készletezés és beszerzés, Gyártás és termelés,
              Projekt kezelés, HR, Munkavédelem, Döntéstámogatás és üzleti intelligencia
            </h3>
            <Card className={"p-p-3"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-4 p-col-align-center p-text-center p-text-lg-left"}>
                  <h3 className={"p-p-0 p-m-0"}>
                    <i className={"pi pi-cloud"} style={{fontSize: '1.2rem'}}></i> Felhő alapú előfizetés
                  </h3>
                </div>
                <div className={"p-col-12 p-lg-4 p-col-align-center p-text-center"}>
                  Státusz: &nbsp;
                  {subscriptionStatus === 'active' &&
                  <span className={"text-success"}>
                    <i className={"pi pi-check text-success"}></i> Aktív előfizetés
                  </span>
                  }
                  {subscriptionStatus === 'terminated' &&
                  <span className={"text-danger"}>
                    <i className={"pi pi-times text-danger"}></i> Lemondott előfizetés
                  </span>
                  }
                  {subscriptionStatus === 'new' &&
                  <span className={"text-warning"}>
                    <i className={"pi pi-exclamation-circle text-warning"}></i> Új előfizetés
                  </span>
                  }
                  {subscriptionStatus === 'expired' &&
                  <span className={"text-danger"}>
                    <i className={"pi pi-exclamation-circle text-danger"}></i> Lejárt előfizetés
                  </span>
                  }
                </div>
                <div className={"p-col-12 p-lg-4 p-col-align-center p-text-center p-text-lg-right"}>
                  {subscriptionStatus !== 'TERMINATED' &&
                  <a className={"p-text-secondary"} onClick={() => {
                    if (window.confirm('A lemondás megerősítésére van szükség! Biztos lemondod a szolgáltatást?')) {
                      SubscriptionCancelService()
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres lemondás!', 'Az előfizetés lemondása sikeresen megtörtént.', '');
                          window.location.replace('/#/');
                          window.App.forceUpdate()
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }
                  }}>Előfizetés lemondása</a>
                  }
                </div>
              </div>
              {view === 'subscription' &&
              <>
                <DataTable className={"p-mt-3"}
                           emptyMessage={"Nincs találat."}
                           value={modules}
                           selectionMode="checkbox"
                           selection={selectedModules}
                           dataKey="code"
                           onSelectionChange={(e) => {
                             setSelectedModules(e.value)
                           }}
                           responsiveLayout="scroll"
                           ref={dt}
                           rowExpansionTemplate={rowExpansionTemplate}
                           expandedRows={expandedRows}
                           onRowExpand={(e) => {
                             if (e.data && e.data.features && e.data.features[0]) {
                               let id = e.data.code
                               setExpandedRows({[id]: true})
                             }
                           }}
                >
                  <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                  <Column header="Modul"
                          body={(rowData) => {
                            return (
                              <>
                                <h3 className={"p-m-0 p-p-0"}>{rowData.module}</h3>
                                <small className={"p-d-block p-text-secondary p-mt-2"}>{rowData.description}</small>
                              </>
                            )
                          }}
                  ></Column>
                  <Column
                    body={(rowData) => {
                      return (
                        <>
                          <a className={"p-button p-button-info"}
                             target={"_blank"}
                             href={process.env.REACT_APP_REGISTRATION_MODULES_URL}
                          >
                            <i className={"pi pi-window-maximize"}></i>&nbsp;Részletek
                          </a>
                        </>
                      )
                    }}
                  ></Column>
                  <Column header="Ár" className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            let _currency
                            _currency = {
                              precision: 0,
                              symbol: '',
                              decimal: ',',
                              thousand: ' ',
                            }
                            return (
                              <>
                                {yearlyPrices === false && rowData.monthlyFee &&
                                <span className={"p-d-block"}>
                                  {currencyFormatter.format(
                                    rowData.monthlyFee, {
                                      precision: _currency.precision,
                                      symbol: _currency.symbol,
                                      decimal: _currency.decimal,
                                      thousand: _currency.thousand,
                                    }
                                  )
                                  } {rowData.currency} / hó
                                </span>
                                }
                                {yearlyPrices === true && rowData.yearlyFee &&
                                <span className={"p-d-block"}>
                                  {currencyFormatter.format(
                                    rowData.yearlyFee, {
                                      precision: _currency.precision,
                                      symbol: _currency.symbol,
                                      decimal: _currency.decimal,
                                      thousand: _currency.thousand,
                                    }
                                  )
                                  } {rowData.currency} / év
                                </span>
                                }
                              </>
                            )
                          }}
                          style={{width: '150px'}}
                  ></Column>
                  <Column style={{width: '120px'}}
                          className={"p-text-nowrap"}
                          header="Érvényesség"
                          body={(rowData) => {
                            return (
                              <>
                                {rowData.expiresAt}
                              </>
                            )
                          }}
                  ></Column>
                  {/*<Column expander style={{width: '3em'}}/>*/}
                </DataTable>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-6 p-lg-2 p-col-align-center p-text-right"}>
                    <span>Éves számlázás:</span>
                  </div>
                  <div className={"p-col-6 p-lg-2 p-col-align-center"}>
                    <InputSwitch
                      checked={yearlyPrices}
                      value={yearlyPrices}
                      onChange={e => setYearlyPrices(e.target.value)}/>
                  </div>
                  <div className={"p-col-12 p-lg-4 p-text-center p-col-align-center"}>
                    Fizetendő: <strong>{yearlyPrices === false ? (<>{currencyFormatter.format(
                    tot.totMonthly, {
                      precision: 0,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  )}</>) : (<>{currencyFormatter.format(
                    tot.totYearly, {
                      precision: 0,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  )
                  }</>)} HUF
                    + ÁFA</strong> {yearlyPrices === false ? (<>/ hó</>) : (<>/ év</>)}
                  </div>
                  <div className={"p-col-12 p-lg-4 p-text-right"}>
                    <Button label="Előfizetés" className={"p-button-success p-button-lg p-d-block p-d-lg-inline"}
                            loading={loading}
                            icon={"pi pi-arrow-right"}
                            onClick={() => {
                              setLoading(true)
                              let paymentFrequency = 'monthly'
                              if (yearlyPrices === true) {
                                paymentFrequency = 'yearly'
                              }
                              let modules = []
                              Object.entries(selectedModules).forEach((entry) => {
                                modules.push({
                                  code: entry[1].code,
                                })
                              })
                              let subscriptionData = {
                                frequency: paymentFrequency,
                                modules: modules
                              }
                              if (modules && modules[0]) {
                                SubscriptionAddService(subscriptionData)
                                  .then(response => {
                                  })
                                  .catch(error => {
                                  })
                                // SubscriptionPreparePayService()
                                //   .then(response => {
                                setView('payment_prepare')
                                setLoading(false)
                                //   setPaymentPrepare(response.data)
                                // })
                                // .catch(error => {
                                //   window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                //   setLoading(false)
                                // })
                              } else {
                                setLoading(false)
                                window.App.toastShow('warn', 'Legalább egy előfizetési csomag szükséges!', 'Kérlek legalább egy csomagot jelölj meg az előfizetéshez');
                              }
                            }}
                    />
                  </div>
                </div>
                <div className={"p-grid p-mt-2"}>
                  <div className={"p-col"}>
                    <Message className={"w-100"}
                             severity="info"
                             text="
                  Előfizetési csomag módosításakor az érvényes funkciók lejárati idejét a kiválasztott időszakkal meghosszabítjuk.
                              "></Message>
                  </div>
                </div>
              </>
              }
              {view === 'payment_prepare' &&
              <>
                <Form onSubmit={paymentPrepareOnSubmit} initialValues={paymentPrepare}
                      validate={paymentPrepareValidate} render={({handleSubmit}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col-12 p-lg-4"}>
                        <Field name="paymentMethod" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="paymentMethod"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Fizetési mód</label>
                            <span className="p-input-icon-right">
                                  {paymentPrepare && paymentPrepare.paymentMethod ? (
                                    <Dropdown
                                      options={[
                                        {name: "SimplePay bankkártyás fizetés", value: "simplepay"},
                                        // {name: "Átutalás", value: "transfer"},
                                      ]}
                                      optionLabel="name"
                                      optionValue="value"
                                      id={"paymentMethod"}
                                      emptyMessage={"Még nincs fizetési mód..."}
                                      onChange={(e) => {
                                        setPaymentPrepare({
                                          ...paymentPrepare,
                                          paymentMethod: e.value
                                        })
                                      }}
                                      value={paymentPrepare.paymentMethod}
                                    />
                                  ) : (
                                    <Dropdown
                                      options={[
                                        {name: "SimplePay bankkártyás fizetés", value: "simplepay"},
                                        // {name: "Átutalás", value: "transfer"},
                                      ]}
                                      optionLabel="name"
                                      optionValue="value"
                                      id={"paymentMethod"}
                                      emptyMessage={"Még nincs fizetési mód..."}
                                      onChange={(e) => {
                                        setPaymentPrepare({
                                          ...paymentPrepare,
                                          paymentMethod: e.value
                                        })
                                      }}
                                    />
                                  )}
                              </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-4"}>
                        <Field name="invoiceCompany" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompany"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Számlázási név
                            </label>
                            <span className="p-input-icon-right">
                                <InputText id="invoiceCompany" {...input}
                                           onChange={(e) => {
                                             setPaymentPrepare({
                                               ...paymentPrepare,
                                               invoiceCompany: e.target.value
                                             })
                                           }}
                                           value={paymentPrepare.invoiceCompany}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                              </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-4"}>
                        <Field name="invoiceCompanyVatNumber" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyVatNumber"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Adószám</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyVatNumber" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyVatNumber: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyVatNumber}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-3"}>
                        <Field name="invoiceCompanyEmail" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyEmail"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Email cím</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyEmail" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyEmail: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyEmail}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-2"}>
                        <Field name="invoiceCompanyAddressZip" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyAddressZip"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>IRSZ</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyAddressZip" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyAddressZip: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyAddressZip}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-3"}>
                        <Field name="invoiceCompanyAddressCity" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyAddressCity"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Város</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyAddressCity" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyAddressCity: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyAddressCity}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-4"}>
                        <Field name="invoiceCompanyAddressDetail" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyAddressDetail"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Cím</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyAddressDetail" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyAddressDetail: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyAddressDetail}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <p>
                      <small>
                        Tudomásul veszem, hogy a Trackie.io Kft. (1025 Budapest, Ruthén
                        út 28.) adatkezelő által az app.trackie.io felhasználói adatbázisában tárolt alábbi
                        személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az
                        adatkezelő által továbbított adatok köre az alábbi: számlázási adatok, email cím. Az
                        adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a
                        SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:
                        http://simplepay.hu/vasarlo-aff
                      </small>
                    </p>
                    <p>
                      <small>
                        Az ismétlődő bankkártyás fizetés (továbbiakban: „Ismétlődő fizetés”) egy, a SimplePay által
                        biztosított bankkártya elfogadáshoz tartozó funkció, mely azt jelenti, hogy a Vásárló által a
                        regisztrációs tranzakció során megadott bankkártyaadatokkal a jövőben újabb fizetéseket lehet
                        kezdeményezni a bankkártyaadatok újbóli megadása nélkül. Az ismétlődő fizetés ún. „eseti
                        hozzájárulásos” típusa minden tranzakció esetében a Vevő eseti jóváhagyásával történik, tehát,
                        Ön
                        valamennyi jövőbeni fizetésnél jóvá kell, hogy hagyja a tranzakciót. A sikeres fizetés tényéről
                        Ön
                        minden esetben a hagyományos bankkártyás fizetéssel megegyező csatornákon keresztül értesítést
                        kap.
                        Az Ismétlődő fizetés igénybevételéhez jelen nyilatkozat elfogadásával Ön hozzájárul, hogy a
                        sikeres regisztrációs tranzakciót követően jelen webshopban (app.trackie.io) Ön az
                        itt található felhasználói fiókjából kezdeményezett későbbi fizetések a bankkártyaadatok újbóli
                        megadása nélkül menjenek végbe.
                        Figyelem(!): a bankkártyaadatok kezelése a kártyatársasági szabályoknak megfelelően történik. A
                        bankkártyaadatokhoz sem a Kereskedő, sem a SimplePay nem fér hozzá. A Kereskedő által tévesen
                        vagy
                        jogtalanul kezdeményezett ismétlődő fizetéses tranzakciókért közvetlenül a Kereskedő felel,
                        Kereskedő fizetési szolgáltatójával (SimplePay) szemben bármilyen igényérvényesítés kizárt.
                      </small>
                    </p>
                    <div className={"p-grid p-mt-1"}>
                      <div className={"p-col-2 p-lg-1 p-col-align-center"}>
                        <div className="p-field p-fluid">
                          <InputSwitch
                            className={""}
                            checked={acceptTerms}
                            value={acceptTerms}
                            onChange={e => setAcceptTerms(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className={"p-col p-col-align-center"}>
                        <small className={"p-d-block"}>A feltételeket és irányelveket megismertem és elfogadom!</small>
                      </div>
                    </div>
                    <div className={"p-grid"}>
                      <div className={"p-col-2 p-lg-1 p-col-align-center"}>
                        <div className="p-field p-fluid">
                          <InputSwitch
                            className={""}
                            checked={acceptDatatrans}
                            value={acceptDatatrans}
                            onChange={e => setAcceptDatatrans(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className={"p-col p-col-align-center"}>
                        <small className={"p-d-block"}>A fenti adattovábbítási nyilatkozatot megértettem és
                          elfogadom!</small>
                      </div>
                    </div>
                    <div className={"p-grid"}>
                      <div className={"p-col-2 p-lg-1 p-col-align-center"}>
                        <div className="p-field p-fluid">
                          <InputSwitch
                            className={""}
                            checked={acceptRecurring}
                            value={acceptRecurring}
                            onChange={e => setAcceptRecurring(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className={"p-col p-col-align-center"}>
                        <small className={"p-d-block"}>Az ismétlődő fizetésre vonatkozó fenti tájékoztatót átolvastam,
                          annak tartalmát tudomásul veszem és elfogadom.</small>
                      </div>
                    </div>
                    <hr/>
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col-6 p-lg-2 p-col-align-center p-text-right"}>
                        <span>Éves számlázás:</span>
                      </div>
                      <div className={"p-col-6 p-lg-2 p-col-align-center"}>
                        <InputSwitch
                          checked={yearlyPrices}
                          value={yearlyPrices}
                          onChange={e => setYearlyPrices(e.target.value)}/>
                      </div>
                      <div className={"p-col-12 p-lg-4 p-text-center p-col-align-center"}>
                        Fizetendő: <strong>{yearlyPrices === false ? (<>{currencyFormatter.format(
                        tot.totMonthly, {
                          precision: 0,
                          symbol: '',
                          decimal: ',',
                          thousand: ' ',
                        }
                      )}</>) : (<>{currencyFormatter.format(
                        tot.totYearly, {
                          precision: 0,
                          symbol: '',
                          decimal: ',',
                          thousand: ' ',
                        }
                      )
                      }</>)} HUF
                        + ÁFA</strong> {yearlyPrices === false ? (<>/ hó</>) : (<>/ év</>)}
                      </div>
                      <div className={"p-col-12 p-lg-4 p-text-right"}>
                        {/*<Button label="Fizetés" className={"disabled p-button-success p-button-lg"}*/}
                        {/*        loading={loading}*/}
                        {/*        icon={"pi pi-arrow-right"}*/}
                        {/*        type="button"*/}
                        {/*        onClick={() => {*/}
                        {/*          alert('Az előfizetői rendszer telepítése folyamatban van. Az előfizetői fiókok jelenleg korlátlanul használhatók.')*/}
                        {/*        }}*/}
                        {/*/>*/}
                        {acceptTerms === true && acceptDatatrans === true && acceptRecurring === true ? (
                          <Button label="Fizetés" className={"p-button-success p-button-lg p-d-block p-d-lg-inline"}
                                  loading={loading}
                                  icon={"pi pi-arrow-right"}
                                  type="submit"
                          />
                        ) : (
                          <Button label="Fizetés" className={"disabled p-button-success p-button-lg p-d-block p-d-lg-inline"}
                                  loading={loading}
                                  icon={"pi pi-arrow-right"}
                                  type="button"
                                  onClick={() => {
                                    alert('A Felhasználási feltételek, az Adattovábbítási feltételek, illetve az Ismétlődő bankkártyás fizetésről szóló feltételek ismerete és elfogadása kötelező!')
                                  }}
                          />
                        )}
                      </div>
                    </div>
                  </form>
                )}/>
              </>
              }
              {view === 'payment_return' &&
              <div className={"p-mt-5"}>
                {paymentStatus &&
                <>
                  {paymentStatus === 'SUCCESS' &&
                  <>
                    <Message severity="success" className={"p-d-block p-text-center p-mb-1"}
                             text="Sikeres fizetés!" style={{fontSize: '1.4em'}}></Message>
                    <Message severity="warn" text={"SimplePay tranzakció azonosító: " + paymentTrId}/>
                    <p className={"text-center"}>{paymentStatusMessage}</p>
                    <h4>Köszönjük előfizetésed!</h4>
                    <p>
                      Sikeresen előfizettél a kiválasztott modulokra. Az előfizetésedről számlát küldtünk emailben. A
                      tranzakció részleteit partnerünk emailben kézbesíti részedre.
                    </p>
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col p-text-right"}>
                        <Link to={"/subscriptions"}
                              className={"p-button p-button-success p-button-lg"}
                              onClick={() => {
                                //setView('subscription')
                                setTimeout(function () {
                                  window.location.reload()
                                }, 500)
                              }}
                        >
                          <i className={"pi pi-arrow-right"}></i>&nbsp;Előfizetői fiók véglegesítése
                        </Link>
                      </div>
                    </div>
                  </>
                  }
                  {paymentStatus === 'FAIL' &&
                  <>
                    <Message severity="error" className={"p-d-block p-text-center p-mb-1"}
                             text="Sikertelen fizetés!" style={{fontSize: '1.4em'}}></Message>
                    <Message severity="warn" text={"SimplePay tranzakció azonosító: " + paymentTrId}/>
                    <h4>Sikertelen tranzakció.</h4>
                    <p>
                      Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét. Amennyiben minden adatot
                      helyesen adott meg, a visszautasítás okának kivizsgálása kapcsán kérjük, szíveskedjen kapcsolatba
                      lépni kártyakibocsátó bankjával.
                    </p>
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col p-text-right"}>
                        <Link to={"/subscriptions"}
                              className={"p-button p-button-success p-button-lg"}
                              onClick={() => {
                                setView('subscription')
                                setTimeout(function () {
                                  window.location.reload()
                                }, 500)

                              }}
                        >
                          Előfizetői fiók áttekintése
                        </Link>
                      </div>
                    </div>
                  </>
                  }
                  {paymentStatus === 'TIMEOUT' &&
                  <>
                    <Message severity="error" className={"p-d-block p-text-center p-mb-1"}
                             text="Megszakított fizetés!" style={{fontSize: '1.4em'}}></Message>
                    <h4>A fizetés időtúllépés miatt megszakadt.</h4>
                    <p>
                      Az előfizetési folyamat később újra megkezdhető.
                    </p>
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col p-text-right"}>
                        <Link to={"/subscriptions"}
                              className={"p-button p-button-success p-button-lg"}
                              onClick={() => {
                                setView('subscription')
                                setTimeout(function () {
                                  window.location.reload()
                                }, 500)

                              }}
                        >
                          Előfizetői fiók áttekintése
                        </Link>
                      </div>
                    </div>
                  </>
                  }
                  {paymentStatus === 'CANCEL' &&
                  <>
                    <Message severity="warn" className={"p-d-block p-text-center"}
                             text="Visszavont fizetés!" style={{fontSize: '1.4em'}}></Message>
                    <h4>A fizetési kísérletet megszakítottad!</h4>
                    <p>
                      Az előfizetési folyamat később újra megkezdhető.
                    </p>
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col p-text-right"}>
                        <Link to={"/subscriptions"}
                              className={"p-button p-button-success p-button-lg"}
                              onClick={() => {
                                setView('subscription')
                                setTimeout(function () {
                                  window.location.reload()
                                }, 500)

                              }}
                        >
                          Előfizetői fiók áttekintése
                        </Link>
                      </div>
                    </div>
                  </>
                  }
                </>
                }
              </div>
              }
              {view === 'payment_transfer' &&
              <div className={"p-mt-5"}>
                <h4>Köszönjük előfizetésed!</h4>
                <Message severity="warn" className={"p-d-block p-text-center p-mb-3"}
                         text="Az átutaláshoz szükséges adatok" style={{fontSize: '1.4em'}}></Message>
                Összeg: <strong>{yearlyPrices === false ? (<>{tot.totMonthly * 1.27}</>) : (<>{tot.totYearly * 1.27}</>)} HUF</strong>
                <br/>
                Közlemény: <strong>{paymentPrepare.invoiceCompanyVatNumber}</strong>
                <br/>
                Számlaszám: <strong>11711003-21474419</strong>
                <br/>
                Címzett: <strong>Trackie.io Kft.</strong>
                <p>
                  Az összeg beérkezésétől számított egy munkanapon belül aktiváljuk előfizetésed!
                </p>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col p-text-right"}>
                    <Link to={"/subscriptions"}
                          className={"p-button p-button-success p-button-lg"}
                          onClick={() => {
                            setView('subscription')
                            setTimeout(function () {
                              window.location.reload()
                            }, 500)

                          }}
                    >
                      Előfizetői fiók áttekintése
                    </Link>
                  </div>
                </div>
              </div>
              }
            </Card>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col p-text-right"}>
                <a className={""}
                   target={"_blank"}
                   href={process.env.REACT_APP_REGISTRATION_MODULES_URL}
                >
                  <i className={"pi pi-window-maximize"}></i>&nbsp;További modulok vagy privát felhő előfizetés érdekel!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withTranslation('common')(SubscriptionPage);
