import axios from "axios";

export default function ReceiptNavNewService(receiptId) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/nav-report/" + receiptId,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
