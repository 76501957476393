import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, Form} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";

import ProductionitemFormService from "../../services/productionitem/ProductionitemFormService";
import {AutoComplete} from "primereact/autocomplete";
import TechnologyListService from "../../services/technology/TechnologyListService";
import MachineListService from "../../services/machine/MachineListService";
import EmployeeListService from "../../services/employee/EmployeeListService";
import ProductionitemShowService from "../../services/productionitem/ProductionitemShowService";
import {addLocale} from "primereact/api";
import UnitListService from "../../services/unit/UnitListService";
import {Message} from "primereact/message";
import {InputMask} from "primereact/inputmask";
import PartnerListService from "../../services/partner/PartnerListService";

const ProductionitemFormComponent = (props) => {

  const [formData, setFormData] = useState({
    projectItem: {
      id: props.projectitemId
    },
    quantity: 1,
    hasSubcontractor: false,
  });
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if ((window.App.state.productionitemFormMethod && window.App.state.productionitemFormMethod === 'update')) {
      ProductionitemFormService(data, 'put', props.initialValues.id)
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProductionitemList: true,
            rerenderScheduleitemscalendarCalendar: true,
            sidebarProductionitemUpdate: false,
            dialogProductionitem: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProductionitemFormService(data, 'post', props.projectId)
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderProductionitemList: true,
            sidebarProductionitemUpdate: false,
            dialogProductionitem: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // technology autocomplete
  const [technologies, setTechnologies] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState();

  const searchTechnology = (event) => {
    // setSelectedTechnology(event.query.replace('Nincs találat', '').replace(': ', ''))
    //setSelectedTechnology(event.query)
    TechnologyListService(JSON.stringify({
      filters: {
        name: {
          // value: event.query.replace('Nincs találat', '').replace(': ', '')
          value: event.query
        }
      }
    }))
      .then(response => {
        setTechnologies(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setTechnologies(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedTechnology(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // machine autocomplete
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState();

  const searchMachine = (event) => {
    // setSelectedMachine(event.query.replace('Nincs találat', '').replace(': ', ''))
    //setSelectedMachine(event.query)
    MachineListService(JSON.stringify({
      filters: {
        name: {
          //value: event.query.replace('Nincs találat', '').replace(': ', '')
          value: event.query
        }
      }
    }))
      .then(response => {
        setMachines(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setMachines(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedMachine(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // employee autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();

  const searchEmployee = (event) => {
    // setSelectedEmployee(event.query.replace('Nincs találat', '').replace(': ', ''))
    //setSelectedEmployee(event.query)
    EmployeeListService(JSON.stringify({
      filters: {
        name: {
          //value: event.query.replace('Nincs találat', '').replace(': ', '')
          value: event.query
        }
      }
    }))
      .then(response => {
        setEmployees(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setEmployees(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedEmployee(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // partner autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartner = (event) => {
    setSelectedPartner(event.query.replace('Nincs találat', '').replace(': ', ''))
    PartnerListService(JSON.stringify({
      filters:
        {
          name: {value: event.query.replace('Nincs találat', '').replace(': ', '')},
          vendor: {value: true}
        }
    }))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setPartners(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedPartner(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    UnitListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (window.App.state && window.App.state.productionitemRowData && window.App.state.productionitemRowData.id) {
      ProductionitemShowService(window.App.state.productionitemRowData.id)
        .then(response => {
          let hasSubcontractor = false
          setSelectedMachine(response.data.machineName)
          setSelectedEmployee(response.data.employeeName)
          setSelectedTechnology(response.data.technologyName)
          let _formData = response.data
          TechnologyListService(JSON.stringify({
            filters: {
              name: {
                value: response.data.technologyName
              }
            }
          }))
            .then(response => {
              hasSubcontractor = response.data.items[0].subcontractor
              setFormData({
                ..._formData,
                hasSubcontractor: hasSubcontractor
              })
              if (response.data.subcontractorName) {
                setSelectedPartner(response.data.subcontractorName)
              } else {
                setSelectedPartner(null)
              }
            })
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    // if (props && props.initialValues) {
    //   ProductionitemShowService(props.initialValues.id)
    //     .then(response => {
    //       setFormData(response.data)
    //       setSelectedMachine(response.data.machineName)
    //       setSelectedEmployee(response.data.employeeName)
    //       setSelectedTechnology(response.data.technologyName)
    //     })
    //     .catch(error => {
    //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //     })
    // } else if (window.App.state.props && window.App.state.props.id) {
    //   ProductionitemShowService(window.App.state.props.id)
    //     .then(response => {
    //       setFormData(response.data)
    //       setSelectedMachine(response.data.machineName)
    //       setSelectedEmployee(response.data.employeeName)
    //       setSelectedTechnology(response.data.technologyName)
    //     })
    //     .catch(error => {
    //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //     })
    // }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="technology" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="technology"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Technológia</label>
                  <span className="p-d-block">
                            <AutoComplete value={selectedTechnology}
                                          id={"technology"}
                                          suggestions={technologies}
                                          completeMethod={searchTechnology}
                                          forceSelection
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              technology: {id: e.value.id},
                                              hasSubcontractor: e.value.subcontractor
                                            })
                                          }}
                                          onChange={(e) => {
                                            setSelectedTechnology(e.value)
                                            setFormData({
                                              ...formData,
                                              technology: null
                                            })
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedTechnology('')
                                            setFormData({
                                              ...formData,
                                              technology: null
                                            })

                                          }}
                            />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {formData && formData.hasSubcontractor &&
            <div className={"p-col-12 p-lg-6"}>
              <Field name="subcontractor" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="subcontractor"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Alvállalkozó</label>
                  <span className="p-d-block">
                    <AutoComplete value={selectedPartner}
                                  id={"subcontractor"}
                                  suggestions={partners}
                                  forceSelection
                                  completeMethod={searchPartner}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      subcontractor: {id: e.value.id},
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      subcontractor: {id: null},
                                    })
                                    setSelectedPartner(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedPartner('')
                                  }}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            }
            <div className={"p-col-12 p-lg-6"}>
              <Field name="machine" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="machine"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Gép</label>
                  <span className="p-d-block">
                            <AutoComplete value={selectedMachine}
                                          id={"machine"}
                                          suggestions={machines}
                                          completeMethod={searchMachine}
                                          forceSelection
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              machine: {id: e.value.id}
                                            })
                                          }}
                                          onChange={(e) => {
                                            setSelectedMachine(e.value)
                                            setFormData({
                                              ...formData,
                                              machine: null
                                            })
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedMachine('')
                                            setFormData({
                                              ...formData,
                                              machine: null
                                            })
                                          }}
                            />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {/*<div className={"p-col-12 p-lg-4"}>*/}
            {/*  <Field name="employee" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="employee"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>*/}
            {/*      <span>*/}
            {/*                <AutoComplete value={selectedEmployee}*/}
            {/*                              id={"employee"}*/}
            {/*                              suggestions={employees}*/}
            {/*                              completeMethod={searchEmployee}*/}
            {/*                              forceSelection*/}
            {/*                              field={"name"}*/}
            {/*                              delay="1000"*/}
            {/*                              placeholder={"Keresés gépeléssel..."}*/}
            {/*                              className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
            {/*                              onSelect={(e) => {*/}
            {/*                                setFormData({*/}
            {/*                                  ...formData,*/}
            {/*                                  employee: {id: e.value.id}*/}
            {/*                                })*/}
            {/*                              }}*/}
            {/*                              onChange={(e) => {*/}
            {/*                                setSelectedEmployee(e.value)*/}
            {/*                                setFormData({*/}
            {/*                                  ...formData,*/}
            {/*                                  employee: null*/}
            {/*                                })*/}
            {/*                              }}*/}
            {/*                              dropdown*/}
            {/*                              onClear={(e) => {*/}
            {/*                                setSelectedEmployee('')*/}
            {/*                                setFormData({*/}
            {/*                                  ...formData,*/}
            {/*                                  employee: null*/}
            {/*                                })*/}
            {/*                              }}*/}
            {/*                />*/}
            {/*      </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-lg-8"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <div className="p-inputgroup">
                    <InputText id="name" {...input}
                               onChange={(e) => {
                                 setFormData({
                                   ...formData,
                                   name: e.target.value
                                 })
                               }}
                               value={formData.name}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    <Button type="button" icon="pi pi-ellipsis-h" className="p-button-default"
                            onClick={() => {
                              let separator = ""
                              let _selectedTechnology = ""
                              let _selectedMachine = ""
                              if (selectedMachine && selectedMachine.name && selectedMachine.name.toString().length > 1) {
                                _selectedMachine = selectedMachine.name
                              }
                              if (selectedTechnology && selectedTechnology.name && selectedTechnology.name.toString().length > 1) {
                                _selectedTechnology = selectedTechnology.name
                              }
                              if (selectedTechnology && selectedMachine) {
                                separator = ", "
                              }
                              setFormData({
                                ...formData,
                                name: _selectedTechnology + separator + _selectedMachine
                              })
                            }}
                    />
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="externalReference" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="externalReference"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Külső azonosító</label>
                  <span className="p-d-block">
                      <InputText id="externalReference" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     externalReference: e.target.value
                                   })
                                 }}
                                 value={formData.externalReference}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {(formData.status !== 'done' || formData.status !== 'deleted') &&
            <>
              <div className={"p-col-12 p-lg-3"}>
                <Field name="estimatedTime" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="estimatedTime"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Becsült idő (perc)</label>
                    <span className={"p-d-block"}>
                              <InputNumber id="estimatedTime" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               estimatedTime: e.value
                                             })
                                           }}
                                           value={formData.estimatedTime}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </>
            }
            <div className={"p-col-12 p-lg-3"}>
              <Field name="quantity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="quantity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                  <span className="p-d-block">
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="unit" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unit"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egység</label>
                  <span className="p-d-block">
                              {formData && formData.unit && formData.unit.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          value={formData.unit.id}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />)}
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-3"}>
              <Field name="deadline" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="deadline"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Határidő</label>
                  <span className={"p-d-block"}>
                        <InputMask id={"deadline"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.deadline}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         deadline: e.value
                                       })
                                     }
                                   }}
                        />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12"}>
              <Message severity="warn"
                       text="Becsült időráfrdításként megadandó a teljes mennyiségre vonatkozó becsült időráfordítás percben."></Message>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              {/*<Button type="button" label="Megtekintés" icon={"pi pi-eye"}*/}
              {/*        onClick={() => {*/}
              {/*          window.App.setState({*/}
              {/*            sidebarProductionitemShow: true,*/}
              {/*            sidebarProductionitemUpdate: false,*/}
              {/*            props: formData,*/}
              {/*          })*/}
              {/*        }}*/}
              {/*        className="p-button"/>*/}
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProductionitemFormComponent);
