import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {InputSwitch} from "primereact/inputswitch";
import {AutoComplete} from "primereact/autocomplete";

import ReceiptgroupFormService from '../../services/receiptgroup/ReceiptgroupFormService'
import ReceipttypeListService from "../../services/receipttype/ReceipttypeListService";
import CompanyListService from "../../services/company/CompanyListService";
import ReceiptgroupShowService from "../../services/receiptgroup/ReceiptgroupShowService";
import BankaccountListService from "../../services/bankaccount/BankaccountListService";

const ReceiptgroupFormComponent = (props) => {

  const [formData, setFormData] = useState({
    outgoing: true,
    external: false,
    active: true,
  });
  const [loading, setLoading] = useState(false);
  const [receipttypes, setReceipttypes] = useState([]);
  const [bankaccounts, setBankaccounts] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    // if (!data.receiptType) {
    //   errors.receiptType = 'Az típus nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      ReceiptgroupFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderReceiptgroupList: true,
            sidebarReceiptgroupUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ReceiptgroupFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderReceiptgroupList: true,
            sidebarReceiptgroupCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchCompany = (event) => {
    CompanyListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    BankaccountListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setBankaccounts(response.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    ReceipttypeListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setReceipttypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      ReceiptgroupShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          setSelectedCompany(response.data.companyName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col"}>
              <Field name="receiptType" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="receiptType"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                  <span className="p-d-block">
                    {formData && (!formData.counter || formData.counter === 0) ? (
                      <Dropdown options={receipttypes}
                                optionLabel="name"
                                optionValue="id"
                                id={"receiptType"}
                                emptyMessage={"Még nincs típus..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    receiptType: e.value
                                  })
                                }}
                                value={formData.receiptType}
                                showClear
                      />
                    ) : (
                      <Dropdown options={receipttypes}
                                optionLabel="name"
                                optionValue="id"
                                id={"receiptType"}
                                emptyMessage={"Még nincs típus..."}
                                disabled
                                value={formData.receiptType}
                      />
                    )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {formData && formData.external === false &&
            <>
              <div className={"p-col"}>
                <Field name="prefix" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="prefix"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Előtag</label>
                    <span className={"p-d-block"}>
                      {formData && (!formData.counter || formData.counter === 0) ? (
                        <InputText id="prefix" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       prefix: e.target.value
                                     })
                                   }}
                                   value={formData.prefix}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      ) : (
                        <InputText id="prefix" {...input}
                                   disabled
                                   value={formData.prefix}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      )}
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="company" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="company"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                    <span className={"p-d-block"}>
                    {localStorage.getItem('roles').toString().includes('role_company_browse')
                    || localStorage.getItem('roles').toString().includes('role_company_read') ? (
                      <>
                        {formData && formData.counter && formData.counter > 0 && formData.companyName !== null ? (
                          <AutoComplete value={selectedCompany}
                                        disabled
                                        forceSelection
                                        id={"company"}
                                        suggestions={companies}
                                        completeMethod={searchCompany}
                                        field={"name"}
                                        delay="1000"
                                        placeholder={"Keresés gépeléssel..."}
                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}

                          />
                        ) : (
                          <AutoComplete value={selectedCompany}
                                        id={"company"}
                                        suggestions={companies}
                                        completeMethod={searchCompany}
                                        field={"name"}
                                        delay="1000"
                                        forceSelection
                                        placeholder={"Keresés gépeléssel..."}
                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                        onSelect={(e) => {
                                          setFormData({
                                            ...formData,
                                            company: {id: e.value.id}
                                          })
                                        }}
                                        onChange={(e) => {
                                          setFormData({
                                            ...formData,
                                            company: {id: null}
                                          })
                                          setSelectedCompany(e.value)
                                        }}
                                        dropdown
                                        onClear={(e) => {
                                          setSelectedCompany('')
                                        }}
                          />
                        )}
                      </>
                    ) : (
                      <Dropdown disabled className={"disabled"} id={"company"} showClear/>
                    )}
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </>
            }
            {formData && formData.outgoing === true && (formData.receiptType === 'invoice' || formData.receiptType === 'fee_request') &&
              <>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="bankAccount" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="vat"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Alapértelmezett bankszámla</label>
                      <span className={"p-d-block"}>
                              {formData && formData.bankAccount && formData.bankAccount.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              bankAccount: {id: e.target.value}
                                            })
                                          }}
                                          value={formData.bankAccount.id}
                                          options={bankaccounts}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"bankAccount"}
                                          emptyMessage={"Még nincs bankszámla..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              bankAccount: {id: e.target.value}
                                            })
                                          }}
                                          options={bankaccounts}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"bankAccount"}
                                          emptyMessage={"Még nincs bankszámla..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              )}
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </>
            }
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="outgoing" render={({input, meta}) => (
                <div className="p-grid p-pt-2">
                  <div className={"p-col-6 p-lg-2 p-xl-2"}>
                    {formData && (!formData.counter || formData.counter === 0) ? (
                      <InputSwitch
                        className={"p-mt-1"}
                        checked={formData.outgoing}
                        value={formData.outgoing}
                        onChange={e => setFormData({...formData, outgoing: e.target.value})}/>
                      ):(
                      <InputSwitch
                        className={"p-mt-1 disabled"}
                        checked={formData.outgoing}
                        value={formData.outgoing}
                        disaled />
                      )}
                  </div>
                  <div className={"p-col-6 p-lg-10 p-xl-10 p-col-align-center"}>
                    <label htmlFor="outgoing"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Kimenő bizonylatok</label>
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="external" render={({input, meta}) => (
                <div className="p-grid p-pt-2">
                  <div className={"p-col-6 p-lg-2 p-xl-2"}>
                    {formData && (!formData.counter || formData.counter === 0) ? (
                      <InputSwitch
                        className={"p-mt-1"}
                        checked={formData.external}
                        value={formData.external}
                        onChange={e => setFormData({...formData, external: e.target.value})}/>
                      ):(
                      <InputSwitch
                        className={"p-mt-1 disabled"}
                        checked={formData.external}
                        value={formData.external}
                        disabled />
                      )}
                  </div>
                  <div className={"p-col-6 p-lg-10 p-xl-10 p-col-align-center"}>
                    <label htmlFor="external"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Külső rendszerből származó
                      bizonylat</label>
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="active" render={({input, meta}) => (
                <div className="p-grid p-pt-2">
                  <div className={"p-col-6 p-lg-2 p-xl-2"}>
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.active}
                      value={formData.active}
                      onChange={e => setFormData({...formData, active: e.target.value})}/>
                  </div>
                  <div className={"p-col-6 p-lg-10 p-xl-10 p-col-align-center"}>
                    <label htmlFor="active"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ReceiptgroupFormComponent);
