import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import moment from "moment";
import download from "js-file-download";

import InventoryListService from "../../services/inventory/InventoryListService";
import WarehouseListService from "../../services/warehouse/WarehouseListService";
import InventoryDownloadService from "../../services/inventory/InventoryDownloadService";

const InventoryListComponent = (props) => {

  const [inventory, setInventory] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProductList: false})
      InventoryListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setInventory(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteInventory = (rowData) => {
    // InventoryDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_inventory_write') &&
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarInventoryUpdate: true,
                    props: rowData
                  })
                }}/>
        }
        {/*{localStorage.getItem('roles').toString().includes('role_inventory_write') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteInventory(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
    WarehouseListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setWarehouses(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [lazyParams, window.App.state.rerenderInventoryList === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={inventory} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="productName" header="Termék" sortable filter filterPlaceholder="Termék"></Column>
        <Column field="warehouseName" header="Raktár" sortable filter
                filterElement={() => {
                  return (
                    <>
                      {lazyParams && lazyParams.filters && lazyParams.filters.warehouse ? (
                        <Dropdown options={warehouses}
                                  id={"warehouse"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs raktár..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'warehouse', '');
                                  }}
                                  value={lazyParams.filters.warehouse.value}
                        />
                      ) : (
                        <Dropdown options={warehouses}
                                  id={"warehouse"}
                                  optionValue={"id"}
                                  optionLabel={"name"}
                                  showClear
                                  emptyMessage={"Még nincs raktár..."}
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'warehouse', '');
                                  }}
                        />
                      )}
                    </>
                  )
                }}
        ></Column>
        <Column field="stock" header="Mennyiség" sortable filter filterPlaceholder="Mennyiség" alignHeader="right" body={(rowData) => {
          return (
            <div className={"p-text-right"}>{rowData.stock}</div>
          )
        }}></Column>
        <Column field="sku" header="Termék cikkszám (SKU)" sortable filter
                filterPlaceholder="Termék cikkszám (SKU)"></Column>
        <Column field="upc" header="Gyártó cikkszám (EAN/UPC)" sortable filter
                filterPlaceholder="Gyártó cikkszám (EAN/UPC)"></Column>
        {/*<Column field="stockFrom" header="Mennyiségtől" sortable filter filterPlaceholder="Mennyiségtől"></Column>*/}
        {/*<Column field="stockUntil" header="Mennyiségig" sortable filter filterPlaceholder="Mennyiségig"></Column>*/}
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Letöltés" icon={"pi pi-download"}
                  onClick={() => {
                    InventoryDownloadService(JSON.stringify(lazyParams))
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres letöltés!', '')
                        download(response.data, localStorage.getItem('networkName')
                          + '_'
                          +
                          'keszlet'
                          + '_'
                          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                          + '.xlsx')
                        setLoading(false)
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!');
                        setLoading(false)
                      })
                  }}
                  className={"p-button p-text-light"}
          />
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(InventoryListComponent);
