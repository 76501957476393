import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputNumber} from "primereact/inputnumber";

import PurchaseorderrequestFormService from '../../services/purchaseorderrequest/PurchaseorderrequestFormService'
import ProjectListService from "../../services/project/ProjectListService";
import ProductListService from "../../services/product/ProductListService";
import PurchaseorderrequestShowService from "../../services/purchaseorderrequest/PurchaseorderrequestShowService";
import PurchaseorderStatusListService from "../../services/purchaseorder/PurchaseorderStatusListService";
import PurchaseorderrequestStatusListService
  from "../../services/purchaseorderrequest/PurchaseorderrequestStatusListService";
import PurchaseorderRequestToOrderService from "../../services/purchaseorder/PurchaseorderRequestToOrderService";

const PurchaseorderrequestShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  // product list
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoadingProducts(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      //window.App.setState({rerenderGuestList: false})
      ProductListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProducts(response.data.items);
          setLoadingProducts(false);
        });
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    if (props && props.initialValues) {
      PurchaseorderrequestShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          if (response.data && response.data.items) {
            setItems(response.data.items)
          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    loadLazyData();
  }, [lazyParams])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>{formData.orderNumber}</h2>
      <div className={"p-grid"}>
        {formData.createdAt &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Létrehozva
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{moment.utc(formData.createdAt).local().format('YYYY-MM-DD HH:mm')}</>
          </p>
        </div>
        }
        {formData.contactName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Kapcsolattartó
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.contactName}</>
          </p>
        </div>
        }
        {formData.status &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Státusz
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.statusName}</>
          </p>
        </div>
        }
        {formData.status === 'new' &&
        <div className={"p-col p-text-right"}>
          <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                  onClick={() => {
                    window.App.setState({
                      sidebarPurchaseorderrequestShow: false,
                      sidebarPurchaseorderrequestUpdate: true,
                      props: formData
                    })
                  }}
          />
        </div>
        }
      </div>
      <hr/>
      <div className={"p-grid p-d-flex p-flex-wrap p-mt-3"}>
        <div className={"p-col-12 p-xl-12 p-d-flex"}>
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-list"}></i> Alapadatok
                </h3>
              </div>
            </div>
          } className={"w-100"}>
            <div className={"p-grid"}>
              {formData.projectName &&
              <div className={"p-col"}>
                <small className={"p-text-uppercase"}>
                  {t('project')}
                </small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  <>{formData.projectName}</>
                </p>
              </div>
              }
              {formData.deadline &&
              <div className={"p-col"}>
                <small className={"p-text-uppercase"}>
                  Határidő
                </small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  <>{moment.utc(formData.deadline).local().format('YYYY-MM-DD HH:mm')}</>
                </p>
              </div>
              }
            </div>
          </Panel>
        </div>
        <div className={"p-col-12 p-xl-12 p-d-flex"}>
          <Panel className={"w-100"}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-list"}></i> Rendelendő termékek
                    </h3>
                  </div>
                </div>
                <DataTable
                  id={"selected"}
                  emptyMessage="Nincs találat."
                  value={items}
                  autoLayout={true}
                  filterDisplay="row"
responsiveLayout="scroll"
                  stripedRows
                >
                  <Column field="productName" header="Név" sortable></Column>
                  {/*<Column field="sku" header="Termék cikkszám (SKU)" sortable></Column>*/}
                  <Column field="quantity" header="Mennyiség"></Column>
                </DataTable>
              </div>
            </div>
          </Panel>
        </div>
      </div>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          {formData && formData.status === 'new' &&
          <Button type="button" label="Beszerzési megrendeléssé alakítás" loading={loading} icon={"pi pi-clone"}
                  onClick={() => {
                    PurchaseorderRequestToOrderService(formData.id)
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres művelet', 'A megrendelés igény megrendelésként lett elmentve.');
                        window.App.setState({
                          sidebarPurchaseorderrequestShow: false,
                          rerenderPurchaseorderrequestList: true,
                          sidebarPurchaseorderShow: true,
                          props: response.data
                        })
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })

                  }}
                  className="p-button p-ml-2"/>
          }
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(PurchaseorderrequestShowComponent);
