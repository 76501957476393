import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputSwitch} from "primereact/inputswitch";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import SafetyFormService from '../../services/safety/SafetyFormService'
import SafetyTypeListService from "../../services/safety/SafetyTypeListService";
import SafetyAttachableWorktypesListService from "../../services/safety/SafetyAttachableWorkypesListService";
import SafetyWorktypeAddService from "../../services/safety/SafetyWorktypeAddService";
import SafetyAttachedWorktypesListService from "../../services/safety/SafetyAttachedWorkypesListService";
import SafetyWorktypeRemoveService from "../../services/safety/SafetyWorktypeRemoveService";

const SafetyFormComponent = (props) => {

  const [formData, setFormData] = useState({
    isBlockingEntry: false,
  });
  const [loading, setLoading] = useState(false);
  const [safetyTypes, setSafetyTypes] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((props && props.initialValues) || formData.id) {
      SafetyFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          if (props.closeForm === true) {
            window.App.setState({
              sidebarSafetyCreate: false,
              sidebarSafetyUpdate: false
            })
          }
          setLoading(false)
          setFormData(response.data)
          loadLazyDataWorktypesSource(response.data.id);
          loadLazyDataWorktypesTarget(response.data.id);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      SafetyFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          if (props.closeForm === true) {
            window.App.setState({
              sidebarSafetyCreate: false,
              sidebarSafetyUpdate: false
            })
          }
          setFormData(response.data)
          setLoading(false)
          loadLazyDataWorktypesSource(response.data.id);
          loadLazyDataWorktypesTarget(response.data.id);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    window.App.setState({
      rerenderSafetyList: true
    })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // worktypes assign
  const [worktypesSource, setWorktypesSource] = useState([]);
  const [worktypesToAdd, setWorktypesToAdd] = useState([]);
  const [worktypesTarget, setWorktypesTarget] = useState([]);
  const [worktypesToDelete, setWorktypesToDelete] = useState([]);

  const [loadingWorktypesSource, setLoadingWorktypesSource] = useState(false);
  const [totalRecordsWorktypesSource, setTotalRecordsWorktypesSource] = useState(0);
  const [rowsWorktypesSource, setRowsWorktypesSource] = useState(10);
  const [lazyParamsWorktypesSource, setLazyParamsWorktypesSource] = useState({
    first: 0,
    rows: rowsWorktypesSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutWorktypesSource = null;
  const loadLazyDataWorktypesSource = (safetyId) => {
    setLoadingWorktypesSource(true);
    if (loadLazyTimeoutWorktypesSource) {
      clearTimeout(loadLazyTimeoutWorktypesSource);
    }
    loadLazyTimeoutWorktypesSource = setTimeout(() => {
      SafetyAttachableWorktypesListService(JSON.stringify(lazyParamsWorktypesSource), safetyId)
        .then(response => {
          setTotalRecordsWorktypesSource(response.data.total_item_count);
          setWorktypesSource(response.data.items);
          setLoadingWorktypesSource(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageWorktypesSource = (event) => {
    let _lazyParams = {...lazyParamsWorktypesSource, ...event};
    setLazyParamsWorktypesSource(_lazyParams);
  }
  const onSortWorktypesSource = (event) => {
    let _lazyParams = {...lazyParamsWorktypesSource, ...event};
    setLazyParamsWorktypesSource(_lazyParams);
  }
  const onFilterWorktypesSource = (event) => {
    let _lazyParams = {...lazyParamsWorktypesSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsWorktypesSource(_lazyParams);
  }
  const pagerWorktypesSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsWorktypesSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const onWorktypesSubmit = () => {
    window.App.setState({loadingWorktypeAdd: true})
    let _worktypesToAdd = []
    Object.entries(worktypesToAdd).map(function (worktype, index) {
      _worktypesToAdd.push(worktype[1].id)
    })
    SafetyWorktypeAddService(_worktypesToAdd, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        loadLazyDataWorktypesSource(formData.id);
        loadLazyDataWorktypesTarget(formData.id);
        window.App.setState({loadingWorktypeAdd: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingWorktypeAdd: false})
      })
  }

  const [loadingWorktypesTarget, setLoadingWorktypesTarget] = useState(false);
  const [totalRecordsWorktypesTarget, setTotalRecordsWorktypesTarget] = useState(0);
  const [rowsWorktypesTarget, setRowsWorktypesTarget] = useState(10);
  const [lazyParamsWorktypesTarget, setLazyParamsWorktypesTarget] = useState({
    first: 0,
    rows: rowsWorktypesTarget,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutWorktypesTarget = null;
  const loadLazyDataWorktypesTarget = (safetyId) => {
    setLoadingWorktypesTarget(true);
    if (loadLazyTimeoutWorktypesTarget) {
      clearTimeout(loadLazyTimeoutWorktypesTarget);
    }
    loadLazyTimeoutWorktypesTarget = setTimeout(() => {
      SafetyAttachedWorktypesListService(JSON.stringify(lazyParamsWorktypesTarget), safetyId)
        .then(response => {
          setTotalRecordsWorktypesTarget(response.data.total_item_count);
          setWorktypesTarget(response.data.items);
          setLoadingWorktypesTarget(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageWorktypesTarget = (event) => {
    let _lazyParams = {...lazyParamsWorktypesTarget, ...event};
    setLazyParamsWorktypesTarget(_lazyParams);
  }
  const onSortWorktypesTarget = (event) => {
    let _lazyParams = {...lazyParamsWorktypesTarget, ...event};
    setLazyParamsWorktypesTarget(_lazyParams);
  }
  const onFilterWorktypesTarget = (event) => {
    let _lazyParams = {...lazyParamsWorktypesTarget, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsWorktypesTarget(_lazyParams);
  }
  const pagerWorktypesTarget = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsWorktypesTarget(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const onWorktypesDelete = () => {
    window.App.setState({loadingWorktypeRemove: true})
    let _worktypesToDelete = []
    Object.entries(worktypesToDelete).map(function (worktype, index) {
      _worktypesToDelete.push(worktype[1].id)
    })
    SafetyWorktypeRemoveService(_worktypesToDelete, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        loadLazyDataWorktypesSource(formData.id);
        loadLazyDataWorktypesTarget(formData.id);
        window.App.setState({loadingWorktypeRemove: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingWorktypeRemove: false})
      })
  }

  useEffect(() => {
    SafetyTypeListService()
      .then(response => {
        setSafetyTypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      setFormData(props.initialValues)
      loadLazyDataWorktypesSource(props.initialValues.id);
      loadLazyDataWorktypesTarget(props.initialValues.id);
    }
    if (formData && formData.id) {
      loadLazyDataWorktypesSource(formData.id);
      loadLazyDataWorktypesTarget(formData.id);
    }
  }, [lazyParamsWorktypesSource, lazyParamsWorktypesTarget])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <div className={"p-grid p-d-flex p-flex-wrap"}>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-d-flex"}>
            <Panel headerTemplate={
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-list"}></i> Alapadatok
                  </h3>
                </div>
              </div>
            } className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                      <span className="p-d-block">
                        <InputText id="name" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       name: e.target.value
                                     })
                                   }}
                                   value={formData.name}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col"}>
                  <Field name="type" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="type"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                      <span className="p-d-block">
                    <Dropdown options={safetyTypes}
                              optionLabel="name"
                              optionValue="id"
                              id={"type"}
                              emptyMessage={"Még nincs munkavédelmi előírás típus..."}
                              value={Number(formData.type)}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  type: e.value
                                })
                              }}
                    />
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col"}>
                  <Field name="isBlockingEntry" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="isBlockingEntry"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Belépés tiltása</label>
                      <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={formData.isBlockingEntry}
                          value={formData.isBlockingEntry}
                          onChange={e => setFormData({...formData, isBlockingEntry: e.target.value})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>

                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success"/>
                  <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
            </Panel>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-d-flex"}>
            <Panel className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Kiválasztható munkatípusok
                      </h3>
                    </div>
                  </div>
                  {formData.id ? (
                    <>
                      <DataTable
                        emptyMessage="Nincs találat."
                        value={worktypesSource} paginator first={lazyParamsWorktypesSource.first}
                        totalRecords={totalRecordsWorktypesSource} onPage={onPageWorktypesSource}
                        onSort={onSortWorktypesSource} sortField={lazyParamsWorktypesSource.sortField}
                        sortOrder={lazyParamsWorktypesSource.sortOrder}
                        onFilter={onFilterWorktypesSource} filters={lazyParamsWorktypesSource.filters}
                        loading={loadingWorktypesSource} lazy
                        paginatorTemplate={pagerWorktypesSource} rows={rowsWorktypesSource}
                        emptyMessage="Nincs találat."
                        autoLayout={true}
                        selection={worktypesToAdd} onSelectionChange={e => {
                        setWorktypesToAdd(e.value)
                      }} dataKey="id"
                        filterDisplay="row"
responsiveLayout="scroll"
                        stripedRows
                        selectionMode="checkbox"
                      >
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
                      </DataTable>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-text-right"}>
                          <Button type="button" label="Kijelöltek hozzáadása" icon={"pi pi-plus"}
                                  loading={window.App.state.loadingWorktypeAdd}
                                  onClick={onWorktypesSubmit}
                                  className="p-button-success"/>
                        </div>
                      </div>
                    </>
                  ) : (
                    <><i className={"pi pi-lock"}></i> A munkatípusok kiválasztása csak mentés után lehetséges!</>
                  )}
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Kiválasztott munkatípusok
                      </h3>
                    </div>
                  </div>
                  {formData.id ? (
                    <>
                      <DataTable
                        emptyMessage="Nincs találat."
                        value={worktypesTarget} paginator first={lazyParamsWorktypesTarget.first}
                        totalRecords={totalRecordsWorktypesTarget} onPage={onPageWorktypesTarget}
                        onSort={onSortWorktypesTarget} sortField={lazyParamsWorktypesTarget.sortField}
                        sortOrder={lazyParamsWorktypesTarget.sortOrder}
                        onFilter={onFilterWorktypesTarget} filters={lazyParamsWorktypesTarget.filters}
                        loading={loadingWorktypesTarget} lazy
                        paginatorTemplate={pagerWorktypesTarget} rows={rowsWorktypesTarget}
                        emptyMessage="Nincs találat."
                        autoLayout={true}
                        selection={worktypesToDelete} onSelectionChange={e => {
                        setWorktypesToDelete(e.value)
                      }} dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="row"
responsiveLayout="scroll"
                        stripedRows
                      >
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
                      </DataTable>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-text-right"}>
                          <Button type="button" label="Kijelöltek eltávolítása"
                                  onClick={onWorktypesDelete}
                                  icon="pi pi-trash"
                                  loading={window.App.state.loadingWorktypeRemove}
                                  className="p-button-danger"/>
                        </div>
                      </div>
                    </>
                  ) : (
                    <><i className={"pi pi-lock"}></i> A munkatípusok megtekintése csak mentés után lehetséges!</>
                  )}
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(SafetyFormComponent);
