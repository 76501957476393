import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";
import currencyFormatter from "currency-formatter";

import FulfillmentItemFormService from '../../services/fulfillment/FulfillmentItemFormService'
import FulfillmentItemShowService from "../../services/fulfillment/FulfillmentItemShowService";
import UnitListService from "../../services/unit/UnitListService";
import ProjectListService from "../../services/project/ProjectListService";
import ProjectitemListService from "../../services/project/ProjectitemListService";

const FulfillmentItemFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      FulfillmentItemFormService(data, props.fulfillmentId, props.initialValues.id, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderFulfillmentItemList: true,
            dialogFullfillmentItemUpdate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      FulfillmentItemFormService(data, props.fulfillmentId, '', 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderFulfillmentItemList: true,
            dialogFullfillmentItemCreate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // projectItem autocomplete
  const [projectItems, setProjects] = useState([]);
  const [selectedProjectItem, setSelectedProjectItem] = useState();

  const searchProjectItem = (event) => {
    //setSelectedProjectItem(event.query.replace('Nincs találat', '').replace(': ', ''))
    //setSelectedProjectItem(event.query)
    ProjectitemListService(JSON.stringify({filters: {name: {value: event.query}}}), props.projectId)
      .then(response => {
        let _items  = []
        response.data.items.map((item, index) => {
          if(item.externalReference !== null) {
            _items.push({
              ...item,
              name: item.name + " (" + item.externalReference + ")"
            })
          } else {
            _items.push({
              ...item,
              name: item.name + " (nincs " + t('externalReference').toLowerCase() + ")"
            })
          }
        })
        setProjects(_items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setProjects(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedProjectItem(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    UnitListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      FulfillmentItemShowService(props.fulfillmentId, props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          setSelectedProjectItem(response.data.projectActionName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="projectItem" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="projectItem"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>{t('project')} tétel</label>
                  <span className="p-d-block">
                                <AutoComplete value={selectedProjectItem}
                                              id={"projectItem"}
                                              suggestions={projectItems}
                                              completeMethod={searchProjectItem}
                                              forceSelection
                                              field={"name"}
                                              delay="1000"
                                              dropdown
                                              placeholder={"Keresés gépeléssel..."}
                                              className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                              onSelect={(e) => {
                                                setFormData({
                                                  ...formData,
                                                  projectItem: {id: e.value.id},
                                                  name: e.value.name,
                                                  netAmount: e.value.unitPrice,
                                                  unit: {
                                                    id: e.value.unit.id
                                                  },
                                                  quantity: e.value.quantity,
                                                })
                                              }}
                                              onChange={(e) => {
                                                setSelectedProjectItem(e.value)
                                              }}
                                              onClear={(e) => {
                                                setSelectedProjectItem('')
                                              }}
                                />
                              </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="quantity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="quantity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                  <span className="p-d-block">
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="unit" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unit"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egység</label>
                  <span className="p-d-block">
                              {formData && formData.unit && formData.unit.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          value={formData.unit.id}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />)}
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col"}>
              <Field name="netAmount" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="netAmount"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egységár</label>
                  <span className="p-d-block">
                              <InputNumber id="netAmount" {...input}
                                           locale="hu-HU"
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               netAmount: e.value
                                             })
                                           }}
                                           value={formData.netAmount}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col"}>
            </div>
            <div className={"p-col p-text-right"}>

              <table align="right" style={{background: "none !important"}}>
                <tr style={{background: "none !important"}}>
                  <td className={"p-text-nowrap p-text-right"} style={{background: "none !important"}}>
                    {formData && formData.netAmount && formData.quantity &&
                    <>
                      Nettó összesen:
                    </>
                    }
                  </td>
                  <td style={{background: "none !important"}}>
                    {formData && formData.netAmount && formData.quantity &&
                    <>
                      {(() => {
                        let currency = 'HUF'
                        if (currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              formData.netAmount * formData.quantity, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}
                          </>
                        )
                      })()}
                    </>
                    }
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(FulfillmentItemFormComponent);
