import axios from "axios";

export default function CompanyNavAddService(companyId, user, pass, signkey, exchangekey, navTest) {
  return axios({
    method: 'put',
    url: process.env.REACT_APP_API_HOST + "/company/upload_nav_data/" + companyId,
    data: {
      navUserName: user,
      navUserPass: pass,
      navXMLSignKey: signkey,
      navXMLExchangeKey: exchangekey,
      navTest: navTest
    },
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
