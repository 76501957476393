import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputNumber} from "primereact/inputnumber";
import {InputMask} from "primereact/inputmask";
import MachineListService from "../../services/machine/MachineListService";
import EmployeeListService from "../../services/employee/EmployeeListService";
import {AutoComplete} from "primereact/autocomplete";
import ProductionjobFormService from "../../services/productionjob/ProductionjobFormService";

const ProjectitemJobsFormComponent = (props) => {

  const [formData, setFormData] = useState({
    projectItem: {
      id: window.App.state.projectitemRowData.id
    }
  });
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.projectActionName) {
    //   errors.projectActionName = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (window.App.state.projectitemJobFormMethod && window.App.state.projectitemJobFormMethod === 'update') {
      ProductionjobFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProjectitemJobsList: true,
            dialogProjectitemJob: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProductionjobFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderProjectitemJobsList: true,
            dialogProjectitemJob: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState();

  const searchMachine = (event) => {
    //setSelectedMachine(event.query.replace('Nincs találat', '').replace(': ', ''))
    MachineListService(JSON.stringify({
      filters: {
        name: {
          value: event.query
        }
      }
    }))
      .then(response => {
        setMachines(response.data.items)
        // if (response.data && response.data.items && response.data.items[0]) {
        //   setMachines(response.data.items)
        // } else {
        //   let string
        //   if (event.query) {
        //     string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        //   } else {
        //     string = 'Nincs találat'
        //   }
        //   setSelectedMachine(string)
        // }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // employees autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const searchEmployees = (event) => {
    //setSelectedEmployee(event.query.replace('Nincs találat', '').replace(': ', ''))
    EmployeeListService(JSON.stringify({filters: {name: {
      value: event.query
    }}}))
      .then(response => {
        setEmployees(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })

  }

  useEffect(() => {
    if (window.App.state.projectitemJobRowData) {
      setFormData({
        ...window.App.state.projectitemJobRowData,
        projectItem: {
          id: window.App.state.projectitemRowData.id
        }
      })
      setSelectedMachine(window.App.state.projectitemJobRowData.machineName)
      setSelectedEmployee(window.App.state.projectitemJobRowData.employeeName)
    }
    if(window.App.state.projectitemJobFormMethod && window.App.state.projectitemJobFormMethod === 'create') {
      setFormData({
        projectItem: {
          id: window.App.state.projectitemRowData.id
        }
      })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            {/*<div className={"p-col-12 p-lg-8"}>*/}
            {/*  <Field name="name" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="name"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>*/}
            {/*      <span>*/}
            {/*          <InputText id="name" {...input}*/}
            {/*                     onChange={(e) => {*/}
            {/*                       setFormData({*/}
            {/*                         ...formData,*/}
            {/*                         name: e.target.value*/}
            {/*                       })*/}
            {/*                     }}*/}
            {/*                     value={formData.name}*/}
            {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
            {/*        </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            {/*<div className={"p-col-12 p-lg-4"}>*/}
            {/*  <Field name="externalId" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="externalId"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Külső azonosító</label>*/}
            {/*      <span>*/}
            {/*          <InputText id="externalId" {...input}*/}
            {/*                     onChange={(e) => {*/}
            {/*                       setFormData({*/}
            {/*                         ...formData,*/}
            {/*                         externalId: e.target.value*/}
            {/*                       })*/}
            {/*                     }}*/}
            {/*                     value={formData.externalId}*/}
            {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
            {/*        </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-xl-6"}>
              <Field name="machine" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="machine"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Gép</label>
                  <span className="p-d-block">
                            <AutoComplete value={selectedMachine}
                                          id={"machine"}
                                          suggestions={machines}
                                          completeMethod={searchMachine}
                                          forceSelection
                                          field={"name"}
                                          delay="1000"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              machine: {id: e.value.id}
                                            })
                                          }}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              machine: {id: null}
                                            })
                                            setSelectedMachine(e.value)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedMachine('')
                                          }}
                            />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-xl-6"}>
              <Field name="employee" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="employee"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>
                  <span className="p-d-block">
                              <AutoComplete value={selectedEmployee}
                                            id={"employee"}
                                            suggestions={employees}
                                            forceSelection
                                            completeMethod={searchEmployees}
                                            field={"name"}
                                            delay="1000"
                                            dropdown
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                employee: {id: e.value.id}
                                              })
                                            }}
                                            onChange={(e) => {
                                              setFormData({
                                                ...formData,
                                                employee: {id: null}
                                              })
                                              setSelectedEmployee(e.value)
                                            }}
                                            onClear={(e) => {
                                              setSelectedEmployee('')
                                            }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="mainTime" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="mainTime"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Főidő</label>
                  <span className="p-d-block">
                              <InputNumber id="mainTime" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               mainTime: e.value
                                             })
                                           }}
                                           value={formData.mainTime}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="auxiliaryTime" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="auxiliaryTime"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mellékidő</label>
                  <span className="p-d-block">
                              <InputNumber id="auxiliaryTime" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               auxiliaryTime: e.value
                                             })
                                           }}
                                           value={formData.auxiliaryTime}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <Field name="quantity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="quantity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség ({window.App.state.projectitemRowData.unitName})</label>
                  <span className="p-d-block">
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="producedFrom" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="producedFrom"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munka kezdete</label>
                  <span className="p-d-block">
                              <InputMask id={"producedFrom"}
                                         mask={"9999-99-99 99:99"}
                                         placeholder={"ÉÉÉÉ-HH-NN OO:PP"}
                                         value={formData.producedFrom}
                                         onChange={(e) => {
                                           if (e.value.indexOf("_") === -1) {
                                             if (e.value === '') {
                                               e.value = null
                                             }
                                             setFormData({
                                               ...formData,
                                               producedFrom: e.value
                                             })
                                           }
                                         }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="producedUntil" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="producedUntil"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munka vége</label>
                  <span className="p-d-block">
                              <InputMask id={"producedUntil"}
                                         mask={"9999-99-99 99:99"}
                                         placeholder={"ÉÉÉÉ-HH-NN OO:PP"}
                                         value={formData.producedUntil}
                                         onChange={(e) => {
                                           if (e.value.indexOf("_") === -1) {
                                             if (e.value === '') {
                                               e.value = null
                                             }
                                             setFormData({
                                               ...formData,
                                               producedUntil: e.value
                                             })
                                           }
                                         }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>

          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProjectitemJobsFormComponent);
