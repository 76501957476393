import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";

import OfferitemPricelistitemFormService from "../../services/offer/OfferitemPricelistitemFormService";
import OfferitemPricelistitemShowService from "../../services/offer/OfferitemPricelistitemShowService";
import CompanyListService from "../../services/company/CompanyListService";
import ProductListService from "../../services/product/ProductListService";
import {AutoComplete} from "primereact/autocomplete";
import PricelistitemListService from "../../services/pricelistitem/PricelistitemListService";

const OfferitemPricelistitemFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      OfferitemPricelistitemFormService(props.offerId, window.App.state.offerItemRowData.id, data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderOfferitemList: true,
            dialogOfferitemPricelistitemUpdate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      OfferitemPricelistitemFormService(props.offerId, window.App.state.offerItemRowData.id, data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderOfferitemList: true,
            dialogOfferitemPricelistitemCreate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    window.App.setState({
      rerenderOfferitemPricelistitemList: true,
      rerenderOfferitemProductList: true,
    })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // pricelistitem autocomplete
  const [pricelistitems, setPricelistitems] = useState([]);
  const [selectedPricelistitem, setSelectedPricelistitem] = useState();

  const searchPricelistitem = (event) => {
    PricelistitemListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setPricelistitems(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (props && props.initialValues) {
      OfferitemPricelistitemShowService(window.App.state.props.id, window.App.state.offerItemRowData.id, window.App.state.listItemRowData.id)
        .then(response => {
          setFormData(response.data)
          setSelectedPricelistitem(response.data.priceListItemName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="priceListItem" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="priceListItem"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Árlista</label>
                  <span className="p-d-block">
                        <AutoComplete value={selectedPricelistitem}
                                      id={"priceListItem"}
                                      suggestions={pricelistitems}
                                      completeMethod={searchPricelistitem}
                                      field={"name"}
                                      delay="1000"
                                      forceSelection
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          priceListItem: {id: e.value.id},
                                          name: e.value.name,
                                          unitPrice: e.value.price,
                                        })
                                      }}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          priceListItem: {id: null},
                                          name: null,
                                          unitPrice: null,
                                        })
                                        setSelectedPricelistitem(e.value)
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedPricelistitem('')
                                      }}
                        />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="quantity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="quantity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                  <span className="p-d-block">
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="unitPrice" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unitPrice"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egységár</label>
                  <span className="p-d-block">
                              <InputNumber id="unitPrice" {...input}
                                           locale="hu-HU"
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               unitPrice: e.value
                                             })
                                           }}
                                           value={formData.unitPrice}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(OfferitemPricelistitemFormComponent);
