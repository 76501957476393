import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import ScheduleListService from "../../services/schedule/ScheduleListService";
import moment from "moment";
import ScheduleDeleteService from "../../services/schedule/ScheduleDeleteService";
import {InputMask} from "primereact/inputmask";
// import ScheduleDeleteService from "../../services/schedule/ScheduleDeleteService";

const ScheduleListComponent = (props) => {

  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'dateFrom',
    sortOrder: -1
  });

  const dt = useRef(null);
  const [dateFromFilterSelectedFrom, setDateFromFilterSelectedFrom] = useState('');
  const [dateFromFilterSelectedUntil, setDateFromFilterSelectedUntil] = useState('');

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderScheduleList: false})
      ScheduleListService(JSON.stringify(lazyParams), props.groupId)
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setSchedule(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*{/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteSchedule = (rowData) => {
    ScheduleDeleteService(props.groupId, rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {/*{localStorage.getItem('roles').toString().includes('role_schedule_write') &&*/}
        {/*<Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"*/}
        {/*        tooltip="Módosítás"*/}
        {/*        onClick={() => {*/}
        {/*          window.App.setState({*/}
        {/*            sidebarScheduleUpdate: true,*/}
        {/*            props: rowData*/}
        {/*          })*/}
        {/*        }}/>*/}
        {/*}*/}
        {localStorage.getItem('roles').toString().includes('role_schedule_read') &&
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarScheduleShow: true,
                    props: rowData,
                  })
                }}/>
        }
        <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                tooltip="Törlés"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    deleteSchedule(rowData)
                  }
                }}
        />
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderScheduleList === true])

  return (
    <>
      <h3 className={"p-mt-0 p-pt-0"}>{props.groupName} műszakok listája</h3>
      <DataTable
        emptyMessage="Nincs találat."
        value={schedule} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>

        <Column field="dateFrom" header="Időponttól" sortable filter className={"p-text-nowrap"}
                filterElement={() => {
                  return (
                    <>
                      <InputMask id={"dateFrom"}
                                 value={dateFromFilterSelectedFrom}
                                 mask={"9999-99-99"}
                                 placeholder={"ÉÉÉÉ-HH-NN"}
                                 onChange={(e) => {
                                   if (e.value.indexOf("_") === -1) {
                                     if (e.value === '') {
                                       e.value = null
                                     }
                                     setDateFromFilterSelectedFrom(e.value)
                                     clearTimeout(window.searchTimeout)
                                     window.searchTimeout = setTimeout(function () {
                                       dt.current.filter(e.value, 'dateFrom', '');
                                     }, 500)
                                   }
                                 }}
                      />
                    </>
                  )
                }}
                body={(rowData) => {
                  return (
                    <>{moment.utc(rowData.dateFrom).local().format('YYYY-MM-DD HH:mm')}</>
                  )
                }}></Column>
        <Column field="dateUntil" header="Időpontig" sortable filter className={"p-text-nowrap"}
                filterElement={() => {
                  return (
                    <>
                      <InputMask id={"dateUntil"}
                                 value={dateFromFilterSelectedUntil}
                                 mask={"9999-99-99"}
                                 placeholder={"ÉÉÉÉ-HH-NN"}
                                 onChange={(e) => {
                                   if (e.value.indexOf("_") === -1) {
                                     if (e.value === '') {
                                       e.value = null
                                     }
                                     setDateFromFilterSelectedUntil(e.value)
                                     clearTimeout(window.searchTimeout)
                                     window.searchTimeout = setTimeout(function () {
                                       dt.current.filter(e.value, 'dateUntil', '');
                                     }, 500)
                                   }
                                 }}
                      />
                    </>
                  )
                }}
                body={(rowData) => {
                  return (
                    <>{moment.utc(rowData.dateUntil).local().format('YYYY-MM-DD HH:mm')}</>
                  )
                }}></Column>
        <Column field="headcount" header="Létszám" sortable></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(ScheduleListComponent);
