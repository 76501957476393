import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import axios from "axios";
import {InputSwitch} from "primereact/inputswitch";
import {Dropdown} from "primereact/dropdown";

const WorkflowfieldFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({
    required: false
  });
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if(options && options[0] && (formData.widget === 'option_one' || formData.widget === 'option_multiple')) {
      data = {
        ...data,
        options: options
      }
    }
    if (formData.id) {
      axios({
        method: 'put',
        url: process.env.REACT_APP_API_HOST + '/workflow_field/' + window.App.state.propsWorkflowstep.id + "/" + formData.id,
        data: data,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderWorkflowfieldList: true,
            sidebarWorkflowfieldUpdate: false
          })
          if (window.App.state.dialogWorkflowfieldForm === true) {
            window.App.setState({dialogWorkflowfieldForm: false})
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_HOST + '/workflow_field/' + window.App.state.propsWorkflowstep.id,
        data: data,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderWorkflowfieldList: true,
            sidebarWorkflowfieldCreate: false
          })
          if (window.App.state.dialogWorkflowfieldForm === true) {
            window.App.setState({dialogWorkflowfieldForm: false})
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if (window.App.state.propsWorkflowfield) {
      setFormData(window.App.state.propsWorkflowfield)
      if(window.App.state.propsWorkflowfield.options && window.App.state.propsWorkflowfield.options[0]) {
        setOptions(window.App.state.propsWorkflowfield.options)
      }
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            {/*<div className={"p-col-12 p-lg-2"}>*/}
            {/*  <Field name="active" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="active"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>*/}
            {/*      <span className="p-d-block">*/}
            {/*        <InputSwitch*/}
            {/*          className={"p-mt-1"}*/}
            {/*          checked={formData.active}*/}
            {/*          value={formData.active}*/}
            {/*          onChange={e => setFormData({...formData, active: e.target.value})}/>*/}
            {/*      </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-lg-12 p-xl-12"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-12"}>
              <Field name="widget" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="widget"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                  <span className="p-d-block">
                      <Dropdown
                        showClear
                        options={[
                          {
                            name: 'Egész szám',
                            value: 'integer'
                          },
                          {
                            name: 'Szöveges mező',
                            value: 'text'
                          },
                          {
                            name: 'Hosszú szövegdoboz',
                            value: 'textarea'
                          },
                          {
                            name: 'Szám',
                            value: 'float'
                          },
                          {
                            name: 'Választható elemek - egy opció',
                            value: 'option_one'
                          },
                          {
                            name: 'Választható elemek - több opció',
                            value: 'option_multiple'
                          },
                        ]}
                        optionLabel="name"
                        optionValue="value"
                        id={"widget"}
                        emptyMessage={"Még nincs típus..."}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            widget: e.value
                          })
                        }}
                        value={formData.widget}
                      />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {formData && formData.widget && (formData.widget === 'option_one' || formData.widget === 'option_multiple') &&
            <div className={"p-col-12 p-lg-12 p-xl-12"}>
              <div className="p-field">
                <label htmlFor="label">
                  {options && options[0] ? (
                    <>
                      Opciók
                    </>
                  ) : (
                    <>
                      Még nincs opció.
                    </>
                  )}
                </label>
                <span className="p-d-block">
                  {options && options[0] &&
                  <>
                    {options.map((option, index) => {
                      return (
                        <div className={'p-grid'}>
                          <div className={"p-col p-col-align-center"}>
                            {option.label}
                          </div>
                          <div className={"p-col p-col-align-center"}>
                            {option.custom_text === true &&
                            <>Szabad szöveg</>
                            }
                          </div>
                          <div className={"p-col p-col-align-center p-text-right"}>
                            <Button type="button"
                                    icon="pi pi-trash"
                                    className="p-button-sm  p-button-danger"
                                    tooltip="Törlés"
                                    onClick={() => {
                                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                                        let _options = options
                                        _options.splice(index, 1)
                                        setOptions(_options)
                                        window.App.forceUpdate()
                                      }
                                    }}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </>
                  }
                </span>
                <div className={"p-grid p-mt-0 p-p-0"}>
                  <div className={"p-col-12 p-lg-3 p-xl-4 p-col-align-center"}>
                    <input type={"text"} id={"newOptionLabel"} className={"p-inputtext p-component"}/>
                  </div>
                  <div className={"p-col-12 p-lg-3 p-xl-4 p-col-align-center"}>
                    <input type={"checkbox"} id={"newOptionText"}/> <label for={"newOptionText"}>Egyedi szövegbevitel</label>
                  </div>
                  <div className={"p-col-12 p-lg-3 p-xl-4 p-col-align-center p-text-right"}>
                    <Button type="button"
                            label="Új opció hozzáadása"
                            icon={"pi pi-plus"}
                            onClick={() => {
                              document.getElementById('newOptionLabel').style.border = '1px solid rgb(206, 212, 218)'
                              if(document.getElementById('newOptionLabel').value) {
                                let _options = options
                                _options.push({
                                  label: document.getElementById('newOptionLabel').value,
                                  custom_text: document.getElementById('newOptionText').checked
                                })
                                setOptions(_options)
                                document.getElementById('newOptionLabel').value = ''
                                window.App.forceUpdate()
                              } else {
                                window.App.toastShow('warn', 'Opció hozzáadása kötelező!');
                                document.getElementById('newOptionLabel').style.border = '1px solid rgb(244, 67, 54)'
                              }
                            }}
                            className="p-button-secondary p-button-sm"/>
                  </div>
                </div>
              </div>
            </div>
            }
            <div className={"p-col-12 p-lg-12 p-xl-12"}>
              <Field name="label" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="label"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Felirat</label>
                  <span className="p-d-block">
                      <InputText id="label" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     label: e.target.value
                                   })
                                 }}
                                 value={formData.label}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-12 p-xl-12"}>
              <Field name="helper" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="helper"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Súgó</label>
                  <span className="p-d-block">
                      <InputText id="helper" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     helper: e.target.value
                                   })
                                 }}
                                 value={formData.helper}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-12"}>
              <Field name="required" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="required"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Költelező mező</label>
                  <span className="p-d-block">
                    <InputSwitch
                      id="required"
                      className={"p-mt-1"}
                      checked={formData.required}
                      value={formData.required}
                      onChange={e => setFormData({...formData, required: e.target.value})}/>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(WorkflowfieldFormComponent);
