import axios from "axios";

export default function ReceiptNavListService(receiptId, query) {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/nav-report/" + receiptId + "?" + query,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
