import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import Kalend, {CalendarView} from 'kalend'
import 'kalend/dist/styles/index.css';
import {Panel} from "primereact/panel";
import hu from "../../translations/hu/kalend.json"
import {MultiSelect} from "primereact/multiselect";

import EmployeeListService from "../../services/employee/EmployeeListService";
import ScheduleitemsAllService from "../../services/scheduleitem/ScheduleitemsAllService";
import moment from "moment";

const ScheduleitemscalendarCalendarComponent = (props) => {

  const [events, setEvents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [filterEmployees, setFilterEmployees] = useState();
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateUntil, setFilterDateUntil] = useState('');

  useEffect(() => {
      if (filterEmployees !== undefined) {
        ScheduleitemsAllService(
          JSON.stringify({
            filters: {
              dateFrom: {value: filterDateFrom},
              dateUntil: {value: filterDateUntil},
              employeeIds: {value: "" + filterEmployees},
            }
          })
        )
          .then(response => {
            window.App.setState({
              rerenderScheduleitemscalendarCalendar: false
            })
            let _events = []
            response.data.map(function (item, index) {
              let color = 'silver'
              if (item.scheduledLoad < 20) {
                color = 'silver'
              }
              else if (item.scheduledLoad < 80) {
                color = 'orange'
              }
              else if (item.scheduledLoad >= 80) {
                color = 'green'
              }
              if(item.scheduledLoad === null) {
                item.scheduledLoad = 0
              }
              if(item.producedLoad === null) {
                item.producedLoad = 0
              }
              if (item && item.dateFrom) {
                _events.push({
                  id: item.id,
                  startAt: item.dateFrom,
                  endAt: item.dateUntil,
                  summary: item.employeeName,
                  color: color,
                  calendarID: item.employee.id,
                })
              }
            })
            setEvents(_events)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      }
    }, [window.App.state.rerenderScheduleitemscalendarCalendar === true, filterEmployees, filterDateFrom, filterDateUntil]
  )

  useEffect(() => {
    EmployeeListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setEmployees(response.data.items)
        let filter = []
        response.data.items.map(function (item, index) {
          filter.push(item.id)
        })
        setFilterEmployees(filter)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <Panel className={"p-pb-2"}
             id={"filters"}
      >
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label>Munkavállaló</label>
              <span className={"p-d-block"}>
                <MultiSelect options={employees}
                             optionLabel="name"
                             optionValue="id"
                             emptyMessage={"Még nincs munkavállaló..."}
                             filter
                             onChange={(e) => {
                               setFilterEmployees(e.value)
                             }}
                             value={filterEmployees}
                />
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div style={{height: '665px'}}>
        <Kalend
          customLanguage={hu}
          onEventClick={(e) => {
            window.App.setState({
              sidebarScheduleditemscalendarUpdate: true,
              props: {id: e.id}
            })
          }}
          onPageChange={(e) => {
            setFilterDateFrom(moment.utc(e.rangeFrom).local().format('YYYY-MM-DD HH:mm'))
            setFilterDateUntil(moment.utc(e.rangeTo).local().format('YYYY-MM-DD HH:mm'))
          }}
          events={events}
          initialDate={new Date().toISOString()}
          hourHeight={20}
          initialView={CalendarView.DAY}
          disabledViews={[CalendarView.THREE_DAYS]}
          timeFormat={'24'}
          weekDayStart={'Monday'}
          language={'en'}
          disabledDragging={true}
        />
      </div>
    </>
  )
}
export default withTranslation('common')(ScheduleitemscalendarCalendarComponent);
