import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import TechnologyListService from "../../services/technology/TechnologyListService";

const TechnologyListComponent = (props) => {

  const [technology, setTechnology] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderTechnologyList: false})
      TechnologyListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setTechnology(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  // const deleteTechnology = (rowData) => {
  //   TechnologyDeleteService(rowData.id)
  //     .then(response => {
  //       window.App.toastShow('success', 'Sikeres törlés!',);
  //       loadLazyData();
  //     })
  //     .catch(error => {
  //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //     })
  // }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_technology_write') &&
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarTechnologyUpdate: true,
                    props: rowData
                  })
                }}/>
        }
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderTechnologyList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={technology} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
responsiveLayout="scroll"
      stripedRows
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
      <Column field="workflowName" header="Munkafolyamat" sortable filter filterPlaceholder="Munkafolyamat"></Column>
      <Column field="subcontractor" header="Alvállalkozó által végzett" className={"p-text-center"} sortable
              body={(rowData) => {
                return (
                  <>
                    {rowData.subcontractor === true ? (
                      <i className={"pi pi-check text-success"}></i>
                    ) : (
                      <i className={"pi pi-times text-danger"}></i>
                    )}
                  </>
                )
              }}
      ></Column>
      <Column field="schedule" header="Műszak beosztás" className={"p-text-center"} sortable
              body={(rowData) => {
                return (
                  <>
                    {rowData.schedule === true ? (
                      <i className={"pi pi-check text-success"}></i>
                    ) : (
                      <i className={"pi pi-times text-danger"}></i>
                    )}
                  </>
                )
              }}
      ></Column>
      <Column field="active" header="Aktív" className={"p-text-center"} sortable
              body={(rowData) => {
                return (
                  <>
                    {rowData.active === true ? (
                      <i className={"pi pi-check text-success"}></i>
                    ) : (
                      <i className={"pi pi-times text-danger"}></i>
                    )}
                  </>
                )
              }}
      ></Column>
    </DataTable>

  )
}
export default withTranslation('common')(TechnologyListComponent);
