import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import moment from "moment";
import {Badge} from "primereact/badge";
import axios from "axios";
import {Button} from "primereact/button";
import ReceiptDeleteService from "../../services/receipt/ReceiptDeleteService";
import ReceiptStornoService from "../../services/receipt/ReceiptStornoService";
import ReceiptDownloadPdfService from "../../services/receipt/ReceiptDownloadPdfService";
import download from "js-file-download";
import ImportqueueStatusesService from "../../services/importqueue/ImportqueueStatusesService";

const FilequeueListComponent = (props) => {

  const [filequeues, setFilequeues] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [expandedRows, setExpandedRows] = useState(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
    filters: {
      movement: {
        value: 'export'
      }
    }
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderFilequeueList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/file_queue?" + JSON.stringify(lazyParams).replace('"multiSortMeta":[],', ''),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setFilequeues(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const status = (rowData) => {
    if (statuses && statuses[0]) {
      let status = statuses.filter(item => item.id === rowData.status)
      if (status[0] && status[0].name) {
        return status[0].name
      }
    }
  }

  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div className="p-ml-3">
          <span className={""}
                dangerouslySetInnerHTML={{__html: data.note.replaceAll(';', '</br>')}}></span>
        </div>
      </>
    );
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.status === 'succesful' ? (
          <Button icon="pi pi-download" className="p-button-sm  p-mr-2"
                  tooltip="Letöltés"
                  onClick={() => {
                    setLoading(true)
                    axios({
                      responseType: 'blob',
                      method: 'get',
                      url: process.env.REACT_APP_API_HOST + "/file_queue/download/" + rowData.id,
                      params: {},
                      headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                        'App-Network': localStorage.getItem("networkId")
                      }
                    })
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres letöltés!',);
                        setLoading(false)
                        download(response.data, localStorage.getItem('networkName')
                          + '_'
                          +
                          'bizonylat_export'
                          + '_'
                          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                          + '.' + rowData.file_extension)
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        setLoading(false)
                      })
                  }}/>
        ) : (
          <Button icon="pi pi-download" className="disabled p-button-sm  p-mr-2"
                  tooltip="Letöltés"
                  onClick={() => {
                    alert('Türelem! A file feldolgozása még folyamatban van.')
                  }}/>
        )}
      </React.Fragment>
    );
  }

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/file_queue_status",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setStatuses(response.data);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    clearInterval(window.queueInterval)
    window.queueInterval = setInterval(function () {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_HOST + "/file_queue?" + JSON.stringify(lazyParams).replace('"multiSortMeta":[],', ''),
        params: {},
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
          'App-Network': localStorage.getItem("networkId")
        }
      })
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setFilequeues(response.data.items);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, 60000)
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderFilequeueList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={filequeues} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows}
      autoLayout={true}
      ref={dt}
      filterDisplay="row"
      responsiveLayout="scroll"
      stripedRows
      dataKey="id"
      rowExpansionTemplate={rowExpansionTemplate}
      expandedRows={expandedRows}
      onRowExpand={(e) => {
        let id = e.data.id
        setExpandedRows({[id]: true})
      }}
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="status" header="Státusz" sortable body={status} className={"p-text-nowrap"}></Column>
      <Column field="createdAt" header="Időpont" sortable body={(rowData) => {
        return (
          <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
        )
      }}></Column>
      <Column field="endpoint" header="Folyamat" sortable></Column>
      <Column field="userName" header="Felhasználó" sortable></Column>
      <Column field="details" header="Részletek"></Column>
      <Column expander style={{width: '3em'}}
              body={(rowData) => {
                if (rowData.note && rowData.note !== null) {
                  let id = rowData.id
                  return (
                    <span style={{cursor: 'pointer'}}
                          onClick={(e) => {
                            setExpandedRows({[id]: true})
                          }}>
                      <Badge value="!" severity="danger"></Badge>
                    </span>
                  )
                } else {
                  return (
                    <></>
                  )
                }
              }}
      />
    </DataTable>

  )
}
export default withTranslation('common')(FilequeueListComponent);
