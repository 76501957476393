import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";
import {MultiSelect} from "primereact/multiselect";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import {Checkbox} from 'primereact/checkbox';
import {Field} from "react-final-form";
import {classNames} from "primereact/utils";
import {AutoComplete} from "primereact/autocomplete";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import download from 'js-file-download';
import currencyFormatter from "currency-formatter";

import ReceiptListService from "../../services/receipt/ReceiptListService";
import PaymentmethodListService from "../../services/paymentmethod/PaymentmethodListService";
import ProjectListService from "../../services/project/ProjectListService";
import CosttypeListService from "../../services/costtype/CosttypeListService";
import ReceiptDeleteService from "../../services/receipt/ReceiptDeleteService";
import ReceiptBatchService from "../../services/receipt/ReceiptBatchService";
import ReceipttypeListService from "../../services/receipttype/ReceipttypeListService";
import {Dialog} from "primereact/dialog";
import ReceiptDeliverynoteInvoiceComponent from "./ReceiptDeliverynoteInvoiceComponent";
import ReceiptDownloadPdfService from "../../services/receipt/ReceiptDownloadPdfService";
import {Badge} from "primereact/badge";
import {InputMask} from "primereact/inputmask";
import ReceiptStornoService from "../../services/receipt/ReceiptStornoService";
import FilequeueListComponent from "../filequeue/FilequeueListComponent";
import axios from "axios";

const ReceiptListComponent = (props) => {

  const {t} = props;
  const [receipt, setReceipt] = useState([]);
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedReceiptType, setSelectedReceiptType] = useState();
  const [selectedPartnerType, setSelectedPartnerType] = useState();
  const [loading, setLoading] = useState(false);
  const [receipttypes, setReceipttypes] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [sum, setSum] = useState({});
  const [downloadDialog, setDownloadDialog] = useState(false);

  const [filterSupplierName, setFilterSupplierName] = useState();
  const [filterCustomerName, setFilterCustomerName] = useState();
  const [filterNetAmountMin, setFilterNetAmountMin] = useState();
  const [filterNetAmountMax, setFilterNetAmountMax] = useState();
  const [filterIssueDateFrom, setFilterIssueDateFrom] = useState();
  const [filterIssueDateTo, setFilterIssueDateTo] = useState();
  const [filterDeliveryDateFrom, setFilterDeliveryDateFrom] = useState();
  const [filterDeliveryDateTo, setFilterDeliveryDateTo] = useState();
  const [filterPaymentDateFrom, setFilterPaymentDateFrom] = useState();
  const [filterPaymentDateTo, setFilterPaymentDateTo] = useState();
  const [filterRetentionPaymentDateFrom, setFilterRetentionPaymentDateFrom] = useState();
  const [filterRetentionPaymentDateUntil, setFilterRetentionPaymentDateUntil] = useState();
  const [filterPaidDateFrom, setFilterPaidDateFrom] = useState();
  const [filterPaidDateTo, setFilterPaidDateTo] = useState();
  const [filterReceiptNumber, setFilterReceiptNumber] = useState();
  const [filterPaid, setFilterPaid] = useState();
  const [filterAllowed, setFilterAllowed] = useState();
  const [filterIncome, setFilterIncome] = useState();
  const [searchCollapsed, setSearchCollapsed] = useState();

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
  });

  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderReceiptList: false})
      if (props.receiptGroup) {
        let _lazyParams = {
          ...lazyParams,
          filters: {
            ...lazyParams.filters,
            receiptGroup: {
              value: props.receiptGroup
            }
          }
        }
        ReceiptListService(JSON.stringify(_lazyParams))
          .then(response => {
            setTotalRecords(response.data.total_item_count);
            setReceipt(response.data.items);
            setLoading(false);
            // tot count by currency
            let currencies = [...new Set(response.data.items.map(item => item.currency))];
            let tot = []
            currencies.forEach((currency) => {
              let netSumByCurrency = 0
              let vatSumByCurrency = 0
              let brSumByCurrency = 0
              if (currency) {
                response.data.items.map((receipt) => {
                  if (receipt.currency == currency) {
                    netSumByCurrency = netSumByCurrency + receipt.netAmount
                    vatSumByCurrency = vatSumByCurrency + receipt.vatAmount
                    brSumByCurrency = brSumByCurrency + receipt.grossAmount
                  }
                })
                tot.push([
                  {
                    currency: currency,
                    sumNet: netSumByCurrency,
                    sumVat: vatSumByCurrency,
                    sumBr: brSumByCurrency
                  }
                ])
              }
            });
            setSum(tot)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            setLoading(false);
          })
      } else if (window.App.state.lazyparamsReceiptList) {
        let state = window.App.state.lazyparamsReceiptList
        let _lazyParams = {
          ...lazyParams,
          filters: {
            ...state.filters,
            ...lazyParams.filters
          }
        }
        Object.entries(window.App.state.lazyparamsReceiptList.filters).map(function (filter, index) {
          if (document.getElementById(filter[0])) {
            document.getElementById(filter[0]).value = filter[1].value
          }
          if (filter[0] === 'isPaid') {
            setFilterPaid(filter[1].value)
          }
          if (filter[0] === 'outgoing') {
            setFilterIncome(filter[1].value)
          }
          if (filter[0] === 'allowed') {
            setFilterAllowed(filter[1].value)
          }
          if (filter[0] === 'receiptType') {
            setSelectedReceiptType(filter[1].value)
          }
        })
        ReceiptListService(JSON.stringify(_lazyParams))
          .then(response => {
            setTotalRecords(response.data.total_item_count);
            setReceipt(response.data.items);
            setLoading(false);
            // tot count by currency
            let currencies = [...new Set(response.data.items.map(item => item.currency))];
            let tot = []
            currencies.forEach((currency) => {
              let netSumByCurrency = 0
              let vatSumByCurrency = 0
              let brSumByCurrency = 0
              if (currency) {
                response.data.items.map((receipt) => {
                  if (receipt.currency == currency) {
                    netSumByCurrency = netSumByCurrency + receipt.netAmount
                    vatSumByCurrency = vatSumByCurrency + receipt.vatAmount
                    brSumByCurrency = brSumByCurrency + receipt.grossAmount
                  }
                })
                tot.push([
                  {
                    currency: currency,
                    sumNet: netSumByCurrency,
                    sumVat: vatSumByCurrency,
                    sumBr: brSumByCurrency
                  }
                ])
              }
            });
            setSum(tot)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            setLoading(false);
          })
      } else {
        ReceiptListService(JSON.stringify(lazyParams))
          .then(response => {
            setTotalRecords(response.data.total_item_count);
            setReceipt(response.data.items);
            setLoading(false);
            // tot count by currency
            let currencies = [...new Set(response.data.items.map(item => item.currency))];
            let tot = []
            currencies.forEach((currency) => {
              let netSumByCurrency = 0
              let vatSumByCurrency = 0
              let brSumByCurrency = 0
              if (currency) {
                response.data.items.map((receipt) => {
                  if (receipt.currency == currency) {
                    netSumByCurrency = netSumByCurrency + receipt.netAmount
                    vatSumByCurrency = vatSumByCurrency + receipt.vatAmount
                    brSumByCurrency = brSumByCurrency + receipt.grossAmount
                  }
                })
                tot.push([
                  {
                    currency: currency,
                    sumNet: netSumByCurrency,
                    sumVat: vatSumByCurrency,
                    sumBr: brSumByCurrency
                  }
                ])
              }
            });
            setSum(tot)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            setLoading(false);
          })
      }
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_receipt_write') &&
        props.view !== 'purchaseorder' &&
        <>
          {rowData.draft === true ? (
            <>
              <Button icon="pi pi-pencil" className="p-button-sm p-button-warning p-mr-2"
                      tooltip="Módosítás"
                      onClick={() => {
                        // let _receiptType = 'invoice'
                        // if (rowData.receiptType === 'invoice' && rowData.outgoing === false) {
                        //   _receiptType = 'cost'
                        // }
                        // let _rowData = {
                        //   ...rowData,
                        //   receiptType: _receiptType
                        // }
                        // window.App.setState({
                        //   sidebarReceiptUpdate: true,
                        //   props: _rowData
                        // })
                        window.App.setState({
                          sidebarReceiptUpdate: true,
                          props: rowData
                        })
                      }}/>
              <Button type="button"
                      tooltip="Törlés"
                      onClick={() => {
                        if (window.confirm('A törlés megerősítésére van szükség!')) {
                          setLoading(true)
                          ReceiptDeleteService(rowData.id)
                            .then(response => {
                              window.App.toastShow('success', 'Sikeres törlés!');
                              window.App.setState({
                                rerenderReceiptList: true,
                                sidebarReceiptUpdate: false,
                                sidebarReceiptCreate: false,
                                sidebarReceiptShow: false,
                              })
                              setLoading(false)
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoading(false)
                            })
                        }
                      }}
                      loading={loading}
                      icon={"pi pi-trash"}
                      className="p-button-sm p-button-danger p-mr-2"/>
            </>
          ) : (
            <>
              <Button icon="pi pi-pencil" className="p-button-sm p-button-warning p-mr-2 disabled"
                      tooltip="Módosítás"/>
              {rowData.receiptType === 'invoice' &&
              <>
                {(rowData.id && rowData.isReversedReceipt !== true && rowData.isStornoReceipt !== true) ? (
                  <Button type="button"
                          tooltip="Sztornó"
                          onClick={() => {
                            if (window.confirm('A törlés megerősítésére van szükség!')) {
                              setLoading(true)
                              ReceiptStornoService(rowData.id)
                                .then(response => {
                                  window.App.toastShow('success', 'Sikeres sztornózás!');
                                  window.App.setState({
                                    rerenderReceiptList: true,
                                    sidebarReceiptUpdate: false,
                                    sidebarReceiptCreate: false,
                                    sidebarReceiptShow: false,
                                  })
                                  setLoading(false)
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  setLoading(false)
                                })
                            }
                          }}
                          loading={loading}
                          icon={"pi pi-times"}
                          className="p-button-sm p-button-danger p-mr-2"/>
                ) : (
                  <Button type="button"
                          tooltip="Sztornó"
                          loading={loading}
                          disabled
                          icon={"pi pi-times"}
                          className="p-button-sm p-button-danger p-mr-2"/>
                )}
              </>
              }
              {rowData.receiptType === 'fee_request' &&
              <>
                <Button type="button"
                        tooltip="Törlés"
                        onClick={() => {
                          if (window.confirm('A törlés megerősítésére van szükség!')) {
                            setLoading(true)
                            ReceiptDeleteService(rowData.id)
                              .then(response => {
                                window.App.toastShow('success', 'Sikeres törlés!');
                                window.App.setState({
                                  rerenderReceiptList: true,
                                  sidebarReceiptUpdate: false,
                                  sidebarReceiptCreate: false,
                                  sidebarReceiptShow: false,
                                })
                                setLoading(false)
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                setLoading(false)
                              })
                          }
                        }}
                        loading={loading}
                        icon={"pi pi-trash"}
                        className="p-button-sm p-button-danger p-mr-2"/>
              </>
              }
            </>
          )}
        </>
        }
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarReceiptShow: true,
                    props: rowData
                  })
                }}/>
        {rowData.outgoing === true ? (
          <Button icon="pi pi-download" className="p-button-sm  p-mr-2"
                  tooltip="Letöltés"
                  onClick={() => {
                    setLoading(true)
                    ReceiptDownloadPdfService(rowData.id)
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres letöltés!',);
                        setLoading(false)
                        download(response.data, localStorage.getItem('networkName')
                          + '_'
                          +
                          'bizonylat'
                          + '_'
                          + rowData.receiptNumber
                          + '.pdf')
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        setLoading(false)
                      })
                  }}/>
        ) : (
          <>
            <Button icon="pi pi-download" className="p-button-sm  p-mr-2 disabled"
                    tooltip="Letöltés"
            />
          </>
        )}
        {(rowData.receiptType === 'invoice' || rowData.receiptType === 'fee_request') && rowData.outgoing === true && rowData.external === false ? (
          <Button icon="pi pi-envelope" className="p-button-sm p-mr-2 p-button-info"
                  tooltip="Számla kézbesítése emailben"
                  onClick={() => {
                    window.App.setState({
                      dialogReceiptSend: true,
                      props: rowData
                    })
                  }}/>
        ) : (
          <Button icon="pi pi-envelope" className="p-button-sm p-mr-2 p-button-info disabled"
                  tooltip="Számla kézbesítése emailben"
          />
        )}
        {rowData.receiptType === 'delivery_note' &&
        <Button icon="pi pi-copy" className="p-button-sm  p-mr-2"
                tooltip="Számla készítése"
                onClick={() => {
                  window.App.setState({
                    dialogReceiptDeliverynoteInvoiceCreate: true,
                    props: rowData
                  })
                }}/>
        }
        {/*{rowData.receiptGroup && rowData.receiptGroup.income && rowData.receiptGroup.income === true*/}
        {/*&& localStorage.getItem('roles').toString().includes('role_receipt_write') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteReceipt(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  //colToggle
  let columns = [{}]
  if (props.view === 'advanced') {
    columns = [
      {
        field: 'deliveryDate',
        header: 'Teljesítés',
        sortable: 'true',
        filter: 'true',
        body: (rowData) => {
          return <>{moment.utc(rowData.deliveryDate).local().format('YYYY-MM-DD')}</>
        },
      },
      {
        field: 'paymentDate',
        header: 'Fizetési határidő',
        sortable: 'true',
        filter: 'true',
        body: (rowData) => {
          return <>{moment.utc(rowData.paymentDate).local().format('YYYY-MM-DD')}</>
        },
      },
      {
        field: 'nextPaymentDate',
        header: 'Következő kifizetés',
        sortable: 'true',
        filter: 'true',
        body: (rowData) => {
          return <>{moment.utc(rowData.nextPaymentDate).local().format('YYYY-MM-DD')}</>
        },
      },
      // {
      //   field: 'vatAmount',
      //   header: 'ÁFA összeg',
      //   sortable: 'true',
      //   filter: 'true',
      //   body: (rowData) => {
      //     let currency
      //     if (rowData.currency === 'HUF') {
      //       currency = {
      //         precision: 2,
      //         symbol: '',
      //         decimal: ',',
      //         thousand: ' ',
      //       }
      //     } else {
      //       currency = {
      //         precision: 2,
      //         symbol: '',
      //         decimal: '.',
      //         thousand: ' ',
      //       }
      //     }
      //     return (
      //       <>
      //         {currencyFormatter.format(
      //           rowData.vatAmount, {
      //             precision: currency.precision,
      //             symbol: currency.symbol,
      //             decimal: currency.decimal,
      //             thousand: currency.thousand,
      //           }
      //         )}&nbsp;{rowData.currency}
      //       </>
      //     )
      //   },
      //   className: 'p-text-right p-text-nowrap'
      // },
    ];
  }
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [selectedColumnsCut, setSelectedColumnsCut] = useState();
  const [columnsCut, setColumnsCut] = useState([]);
  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
    let orderedSelectedColumnsCut = []
    orderedSelectedColumns.map((item) => {
      orderedSelectedColumnsCut.push({
        field: item.field,
        header: item.header
      })
    })
    setSelectedColumns(orderedSelectedColumns);
    setSelectedColumnsCut(orderedSelectedColumnsCut)
    localStorage.removeItem("colsReceiptList")
    localStorage.setItem("colsReceiptList", JSON.stringify(orderedSelectedColumnsCut))
  }
  const columnComponents = selectedColumns.map(col => {
    return <Column key={col.field}
                   field={col.field}
                   header={col.header}
                   sortable={col.sortable}
      // filter={col.filter}
      // filterElement={col.filterElement}
                   body={col.body}
                   className={col.className}/>;
  });

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  // paymentMethod autocomplete
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const searchPaymentMethod = (event) => {
    PaymentmethodListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setPaymentMethods(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // project autocomplete
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();

  const searchProject = (event) => {
    ProjectListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setProjects(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // costtype autocomplete
  const [costtypes, setCosttypes] = useState([]);
  const [selectedCosttype, setSelectedCosttype] = useState('');

  const searchCosttype = (event) => {
    CosttypeListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        setCosttypes(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const searchHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    return (
      <>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center"}>
            <h3 className={"p-p-0 p-m-0"}>
              <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span>
              </button>
              &nbsp; Szűrés
            </h3>
          </div>
          <div className={"p-col-12 p-lg-6 p-text-right p-col-align-center"}>
            <Button className={"p-button-danger p-mr-2 p-text-light"}
                    label="Szűrés törlése" icon="pi pi-times"
                    onClick={() => {
                      setFilterSupplierName('')
                      setFilterCustomerName('')
                      setFilterNetAmountMin('')
                      setFilterNetAmountMax('')
                      setFilterIssueDateFrom('')
                      setFilterIssueDateTo('')
                      setFilterDeliveryDateFrom('')
                      setFilterDeliveryDateTo('')
                      setFilterPaymentDateFrom('')
                      setFilterPaymentDateTo('')
                      setFilterRetentionPaymentDateFrom('')
                      setFilterRetentionPaymentDateUntil('')
                      setFilterPaidDateFrom('')
                      setFilterPaidDateTo('')
                      setFilterReceiptNumber('')
                      setFilterAllowed(null)
                      setFilterPaid(null)
                      setFilterIncome(null)
                      setSelectedPaymentMethod('')
                      setSelectedProject('')
                      setSelectedCosttype('')
                      setSelectedReceiptType('')
                      setLazyParams({
                        first: 0,
                        rows: rows,
                        page: 0,
                        sortField: 'id',
                        sortOrder: 1,
                      })
                      window.App.setState({
                        lazyparamsReceiptList: null
                      })
                    }}
            />
          </div>
        </div>
      </>
    )
  }

  useEffect(() => {
    ReceipttypeListService()
      .then(response => {
        setReceipttypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
    //colToggle
    let columnsCut = []
    columns.map((item) => {
      columnsCut.push({
        field: item.field,
        header: item.header
      })
    })
    setColumnsCut(columnsCut)
    let colsReceiptList = JSON.parse(localStorage.getItem("colsReceiptList"))
    if (colsReceiptList && colsReceiptList[0] && colsReceiptList[0].field) {
      setSelectedColumnsCut(colsReceiptList)
      let orderedSelectedColumns = columns.filter(col => colsReceiptList.some(sCol => sCol.field === col.field));
      setSelectedColumns(orderedSelectedColumns);
    } else {
      let orderedSelectedColumnsCut = []
      columns.map((item) => {
        orderedSelectedColumnsCut.push({
          field: item.field,
          header: item.header
        })
      })
      setSelectedColumnsCut(orderedSelectedColumnsCut)
    }
    // if (window.App.state.lazyparamsReceiptList && window.App.state.lazyparamsReceiptList !== null) {
    //   setLazyParams(window.App.state.lazyparamsReceiptList)
    //   //   Object.entries(window.App.state.lazyparamsReceiptList.filters).map(function (filter, index) {
    //   //     if (document.getElementById(filter[0])) {
    //   //       document.getElementById(filter[0]).value = filter[1].value
    //   //     }
    //   //     if (filter[0] === 'isPaid') {
    //   //       setFilterPaid(filter[1].value)
    //   //     }
    //   //     if (filter[0] === 'outgoing') {
    //   //       setFilterIncome(filter[1].value)
    //   //     }
    //   //     if (filter[0] === 'allowed') {
    //   //       setFilterAllowed(filter[1].value)
    //   //     }
    //   //   })
    // }
  }, [lazyParams, window.App.state.rerenderReceiptList === true])

  return (
    <>
      {props && props.view === 'advanced' &&
      <>
        <h2 className={"text-primary"}>
          {window.App.state.headerReceiptList ? (
            <>{window.App.state.headerReceiptList}</>
          ) : (
            <>Bizonylatok listája</>
          )}
        </h2>
        <Panel headerTemplate={searchHeaderTemplate} toggleable collapsed={searchCollapsed}
               onToggle={(e) => setSearchCollapsed(e.value)}
               className={"p-pb-2"}
               id={"filters"}
        >
          <div className={"p-grid"}>
            {/*<div className={"p-col-12 p-lg-2 p-xl-2"}>*/}
            {/*  <div className="p-field p-fluid">*/}
            {/*    <label>Megjelenítés</label>*/}
            {/*    <span>*/}
            {/*      <MultiSelect value={selectedColumnsCut} options={columnsCut} optionLabel="header"*/}
            {/*                   onChange={onColumnToggle}*/}
            {/*                   selectedItemsLabel="{0} oszlop kiválasztva"*/}
            {/*                   placeholder={'Megjelenítés'}*/}
            {/*      />*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Vállalkozás</label>
                <span className={"p-d-block"}>
                <InputText id="companyName"
                           onChange={(e) => {
                             setFilterSupplierName(e.target.value)
                             let value = e.target.value
                             clearTimeout(window.searchTimeout)
                             window.searchTimeout = setTimeout(function () {
                               dt.current.filter(value, 'companyName', '');
                             }, 500)
                           }}
                           value={filterSupplierName}
                />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Partner</label>
                <span className={"p-d-block"}>
                <InputText id="partnerName"
                           onChange={(e) => {
                             setFilterCustomerName(e.target.value)
                             let value = e.target.value
                             clearTimeout(window.searchTimeout)
                             window.searchTimeout = setTimeout(function () {
                               dt.current.filter(value, 'partnerName', '');
                             }, 500)
                           }}
                           value={filterCustomerName}
                />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-1"}>
              <div className="p-field p-fluid">
                <label>Min. nettó</label>
                <span className={"p-d-block"}>
                <InputNumber id="netAmountMin"
                             locale="hu-HU"
                             minFractionDigits={0}
                             maxFractionDigits={9}
                             onChange={(e) => {
                               setFilterNetAmountMin(e.value)
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(e.value, 'netAmountMin', '');
                               }, 500)
                             }}
                             value={filterNetAmountMin}
                />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-1"}>
              <div className="p-field p-fluid">
                <label>Max. nettó</label>
                <span className={"p-d-block"}>
                <InputNumber id="netAmountMax"
                             locale="hu-HU"
                             minFractionDigits={0}
                             maxFractionDigits={9}
                             onChange={(e) => {
                               setFilterNetAmountMax(e.value)
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(e.value, 'netAmountMax', '');
                               }, 500)
                             }}
                             value={filterNetAmountMax}
                />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Kelt</label>
                <div className={"p-grid"}>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"issueDateFrom"}
                               value={filterIssueDateFrom}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterIssueDateFrom(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'issueDateFrom', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                  <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                    <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                  </div>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"issueDateTo"}
                               value={filterIssueDateTo}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterIssueDateTo(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'issueDateTo', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Teljesítés</label>
                <div className={"p-grid"}>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"deliveryDateFrom"}
                               value={filterDeliveryDateFrom}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterDeliveryDateFrom(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'deliveryDateFrom', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                  <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                    <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                  </div>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"deliveryDateTo"}
                               value={filterDeliveryDateTo}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterDeliveryDateTo(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'deliveryDateTo', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Fizetési határidő</label>
                <div className={"p-grid"}>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"paymentDateFrom"}
                               value={filterPaymentDateFrom}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterPaymentDateFrom(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'paymentDateFrom', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                  <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                    <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                  </div>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"paymentDateTo"}
                               value={filterPaymentDateTo}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterPaymentDateTo(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'paymentDateTo', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Visszatartás határideje</label>
                <div className={"p-grid"}>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"retentionPaymentDateFrom"}
                               value={filterRetentionPaymentDateFrom}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterRetentionPaymentDateFrom(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'retentionPaymentDateFrom', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                  <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                    <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                  </div>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"retentionPaymentDateUntil"}
                               value={filterRetentionPaymentDateUntil}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterRetentionPaymentDateUntil(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'retentionPaymentDateUntil', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>
                  Fizetve dátum
                </label>
                <div className={"p-grid"}>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"paidDateFrom"}
                               value={filterPaidDateFrom}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterPaidDateFrom(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'paidDateFrom', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                  <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                    <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                  </div>
                  <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"paidDateTo"}
                               value={filterPaidDateTo}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterPaidDateTo(e.value)
                                   clearTimeout(window.searchTimeout)
                                   window.searchTimeout = setTimeout(function () {
                                     dt.current.filter(e.value, 'paidDateTo', '');
                                   }, 500)
                                 }
                               }}
                    />
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Számla sorszám</label>
                <span className={"p-d-block"}>
                <InputText id="receiptNumber"
                           onChange={(e) => {
                             setFilterReceiptNumber(e.target.value)
                             let value = e.target.value
                             clearTimeout(window.searchTimeout)
                             window.searchTimeout = setTimeout(function () {
                               dt.current.filter(value, 'receiptNumber', '');
                             }, 500)
                           }}
                           value={filterReceiptNumber}
                />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Fizetés módja</label>
                <span className={"p-d-block"}>
                <AutoComplete value={selectedPaymentMethod}
                              id={"paymentMethod"}
                              suggestions={paymentMethods}
                              completeMethod={searchPaymentMethod}
                              field={"name"}
                              delay="1000"
                              placeholder={"Keresés gépeléssel..."}
                              onSelect={(e) => {
                                dt.current.filter(e.value.id, 'paymentMethod', '');
                                setSelectedPaymentMethod(e.value.name)
                              }}
                              forceSelection
                              dropdown
                              onClear={(e) => {
                                setSelectedPaymentMethod('')
                                dt.current.filter(null, 'paymentMethod', '');
                              }}
                />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>{t('project')}</label>
                <span className={"p-d-block"}>
                <AutoComplete value={selectedProject}
                              id={"project"}
                              suggestions={projects}
                              completeMethod={searchProject}
                              field={"name"}
                              delay="1000"
                              forceSelection
                              dropdown
                              placeholder={"Keresés gépeléssel..."}
                              onSelect={(e) => {
                                dt.current.filter(e.value.id, 'project', '');
                                setSelectedProject(e.value.name)
                              }}
                              onClear={(e) => {
                                setSelectedProject('')
                                dt.current.filter(null, 'project', '');
                              }}
                />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Költségnem</label>
                <span className={"p-d-block"}>
                <AutoComplete value={selectedCosttype}
                              id={"costType"}
                              suggestions={costtypes}
                              forceSelection
                              completeMethod={searchCosttype}
                              field={"name"}
                              delay="1000"
                              placeholder={"Keresés gépeléssel..."}
                              onSelect={(e) => {
                                dt.current.filter(e.value.id, 'costType', '');
                                setSelectedCosttype(e.value.name)
                              }}
                              dropdown
                              onClear={(e) => {
                                setSelectedCosttype('')
                                dt.current.filter(null, 'costType', '');
                              }}
                />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Bizonylat típus</label>
                <span className={"p-d-block"}>
                  <Dropdown options={receipttypes}
                            optionLabel="name"
                            optionValue="id"
                            id={"receiptType"}
                            emptyMessage={"Még nincs típus..."}
                            onChange={(e) => {
                              setSelectedReceiptType(e.value)
                              dt.current.filter(e.value, 'receiptType', '');
                              window.App.setState({
                                lazyparamsReceiptList: {
                                  filters: {
                                    ...window.App.state.lazyparamsReceiptList.filters,
                                    receiptType: {
                                      value: e.value
                                    }
                                  }
                                }
                              })
                            }}
                            showClear
                            value={selectedReceiptType}
                  />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <div className="p-field p-fluid">
                <label>Partner típus</label>
                <span className={"p-d-block"}>
                  <Dropdown options={[
                    {
                      name: "Személy",
                      id: "person"
                    },
                    {
                      name: "Cég",
                      id: "organisation"
                    },
                    {
                      name: "Egyéb",
                      id: "other"
                    },
                  ]}
                            optionLabel="name"
                            optionValue="id"
                            id={"partnerType"}
                            emptyMessage={"Még nincs típus..."}
                            onChange={(e) => {
                              setSelectedPartnerType(e.value)
                              dt.current.filter(e.value, 'partnerType', '');
                              window.App.setState({
                                lazyparamsReceiptList: {
                                  filters: {
                                    ...window.App.state.lazyparamsReceiptList.filters,
                                    partnerType: {
                                      value: e.value
                                    }
                                  }
                                }
                              })
                            }}
                            showClear
                            value={selectedPartnerType}
                  />
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-10 p-pt-5"}>
              <div className="p-fluid p-py-1 p-mt-2">
                <label className={"p-mr-2"}>
                  <TriStateCheckbox inputId="outgoing" id="outgoing" value={filterIncome}
                                    onChange={e => {
                                      dt.current.filter(e.value, 'outgoing', '');
                                      setFilterIncome(e.value)
                                      window.App.setState({
                                        lazyparamsReceiptList: {
                                          filters: {
                                            ...window.App.state.lazyparamsReceiptList.filters,
                                            outgoing: {
                                              value: e.value
                                            }
                                          }
                                        }
                                      })
                                    }}/>
                  &nbsp;
                  Kimenő
                </label>
                <label className={"p-mr-2"}>
                  <TriStateCheckbox inputId="isPaid" id="isPaid" value={filterPaid}
                                    onChange={e => {
                                      dt.current.filter(e.value, 'isPaid', '');
                                      setFilterPaid(e.value)
                                      window.App.setState({
                                        lazyparamsReceiptList: {
                                          filters: {
                                            ...window.App.state.lazyparamsReceiptList.filters,
                                            isPaid: {
                                              value: e.value
                                            }
                                          }
                                        }
                                      })
                                    }}/>
                  &nbsp;
                  Fizetve
                </label>
                <label className={"p-mr-2"}>
                  <TriStateCheckbox inputId="allowed" id="allowed"
                                    value={filterAllowed}
                                    onChange={e => {
                                      dt.current.filter(e.value, 'allowed', '');
                                      setFilterAllowed(e.value)
                                      window.App.setState({
                                        lazyparamsReceiptList: {
                                          filters: {
                                            ...window.App.state.lazyparamsReceiptList.filters,
                                            allowed: {
                                              value: e.value
                                            }
                                          }
                                        }
                                      })
                                    }}/>
                  &nbsp;
                  Jóváhagyva
                </label>
              </div>
            </div>
          </div>
        </Panel>
      </>
      }
      {props.view !== 'advanced' && props.view !== 'purchaseorder' &&
      <>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-12 p-xl-12 p-text-right p-col-align-center p-pt-0"}>
            <Button className={"p-button-outlined p-button-danger p-button-sm p-mr-2"}
                    label="Szűrés törlése" icon="pi pi-times"
                    style={{border: 'none'}}
                    onClick={() => {
                      setLazyParams({
                        first: 0,
                        rows: rows,
                        page: 0,
                        sortField: 'id',
                        sortOrder: 1,
                      })
                    }}
            />
            <Button className={"p-button-outlined  p-button-sm"}
                    style={{border: 'none'}}
                    label="Kiterjesztett nézet" icon="pi pi-window-maximize"
                    onClick={() => {
                      window.App.setState({
                        headerReceiptList: props.receiptGroupName,
                        lazyparamsReceiptList: {
                          first: 0,
                          rows: 10,
                          page: 0,
                          sortField: 'issueDate',
                          sortOrder: 1,
                          filters: {
                            receiptGroup: {value: props.receiptGroup}
                          }
                        },
                        sidebarReceiptList: true
                      })
                    }}
            />
          </div>
        </div>
      </>
      }
      <DataTable
        emptyMessage="Nincs találat."
        value={receipt} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        ref={dt}
        filterDisplay="row"
        responsiveLayout="scroll"
        stripedRows
        selection={selectedReceipts}
        onSelectionChange={e => {
          setSelectedReceipts(e.value)
        }}
        dataKey="id"
        selectionMode="checkbox"
        // rowClassName={(rowData) => {
        //   return {
        //     'statDisplay': rowData,
        //     'statusNavDone': 1 === 1,
        //     'bg-primary2': rowData.navOnlineStatus === null
        //   }
        // }}
      >
        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        {!props.receiptGroup &&
        <Column field="receiptType" header="Típus" body={(rowData) => {
          if (rowData.receiptType === 'invoice') {
            if (rowData.isStornoReceipt === true) {
              return (
                <>Sztornó számla</>
              )
            }
            if (rowData.isReversedReceipt === true) {
              return (
                <>Sztornózott számla</>
              )
            } else {
              return (
                <>Számla</>
              )
            }
          }
          if (rowData.receiptType === 'fee_request') {
            return (
              <>Díjbekérő</>
            )
          }
          if (rowData.receiptType === 'delivery_note') {
            return (
              <>Szállítólevél</>
            )
          }
        }}></Column>
        }
        {props.view === 'advanced' ? (
          <Column field="receiptNumber" header="Sorszám" sortable body={(rowData) => {
            if (rowData.draft === true && rowData.external === false) {
              return (
                <>
                  <Badge value="Vázlat" severity="danger"></Badge>
                </>
              )
            } else {
              return (
                <>{rowData.receiptNumber}</>
              )
            }
          }}></Column>
        ) : (
          <Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"
                  body={(rowData) => {
                    if (rowData.draft === true && rowData.external === false) {
                      return (
                        <>
                          <Badge value="Vázlat" severity="danger"></Badge>
                        </>
                      )
                    } else {
                      return (
                        <>{rowData.receiptNumber}</>
                      )
                    }
                  }}></Column>
        )}
        {props.view === 'advanced' ? (
          <Column field="companyName" header="Vállalkozás" sortable></Column>
        ) : (
          <Column field="companyName" header="Vállalkozás" sortable filter filterPlaceholder="Vállalkozás"></Column>
        )}
        {props.view === 'advanced' ? (
          <Column field="partnerName" header="Partner" sortable></Column>
        ) : (
          <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
        )}
        {props.view === 'advanced' &&
        <Column field="partnerType" header="Partner típus" sortable body={(rowData) => {
          return(
            <>
              {rowData.partnerType === "person" &&
              <>
                Személy
              </>
              }
              {rowData.partnerType === "organisation" &&
              <>
                Cég
              </>
              }
              {rowData.partnerType === "other" &&
              <>
                Egyéb
              </>
              }
            </>
          )
        }}></Column>
        }
        <Column field="issueDate" header="Kelt" sortable className={"p-text-nowrap"} body={(rowData) => {
          return (
            <>{moment.utc(rowData.issueDate).local().format('YYYY-MM-DD')}</>
          )
        }}></Column>
        <Column field="deliveryDate" header="Teljesítés" sortable className={"p-text-nowrap"} body={(rowData) => {
          return (
            <>{moment.utc(rowData.deliveryDate).local().format('YYYY-MM-DD')}</>
          )
        }}></Column>
        {columnComponents}
        <Column field="isPaid" header="Kifizetve" sortable className={"p-text-center p-text-nowrap"}
                body={(rowData) => {
                  if (rowData.isPaid === true) {
                    return (
                      <>
                        <i className={"pi pi-check text-success"}></i>
                        {rowData.paidDate !== null && props.view === 'advanced' &&
                        <>
                          <br/>
                          {moment.utc(rowData.paidDate).local().format('YYYY-MM-DD')}
                        </>
                        }
                      </>
                    )
                  } else {
                    return (
                      <>
                        <i className={"pi pi-times text-danger"}></i>
                      </>
                    )
                  }
                }}></Column>
        {props.receiptType !== 'delivery_note' &&
        <Column field="netAmount" header="Nettó összeg" sortable className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.netAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        }
        {props.receiptType !== 'delivery_note' && props.view === 'advanced' &&
        <Column field="vatAmount" header="ÁFA összeg" sortable className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.vatAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        }
        {props.receiptType !== 'delivery_note' &&
        <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                alignHeader="right"
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.grossAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        }
        {process.env.REACT_APP_COMPANY_NAV_INTERFACE === 'true' && props.view !== 'advanced' &&
        <Column field="navOnlineStatus" header="NAV adatszolgáltatás" sortable filter className={"p-text-nowrap"}
                body={(rowData) => {
                  return (
                    <>
                      {rowData.navOnlineStatus === 'PROCESSING' &&
                      <>
                        <span className={"text-warning"}><i className={"pi pi-exclamation-circle"}></i> Feldolgozás folyamatban</span>
                        {/*<a onClick={() => {*/}
                        {/*  setLoading(true)*/}
                        {/*  ReceiptNavRefreshService(rowData.id)*/}
                        {/*    .then(response => {*/}
                        {/*      setLoading(false)*/}
                        {/*      lazyParams()*/}
                        {/*    })*/}
                        {/*    .catch(error => {*/}
                        {/*      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);*/}
                        {/*      setLoading(false)*/}
                        {/*    })*/}
                        {/*}}*/}
                        {/*   className={"p-d-block p-mt-1"}><i className={"pi pi-refresh"}></i> Frissítés</a>*/}
                      </>
                      }
                      {rowData.navOnlineStatus === 'RECEIVED' &&
                      <>
                        <span className={"text-warning"}><i className={"pi pi-exclamation-circle"}></i> Információk átadva</span>
                        {/*<a onClick={() => {*/}
                        {/*  setLoading(true)*/}
                        {/*  ReceiptNavRefreshService(rowData.id)*/}
                        {/*    .then(response => {*/}
                        {/*      setLoading(false)*/}
                        {/*      lazyParams()*/}
                        {/*    })*/}
                        {/*    .catch(error => {*/}
                        {/*      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);*/}
                        {/*      setLoading(false)*/}
                        {/*    })*/}
                        {/*}}*/}
                        {/*   className={"p-d-block p-mt-1"}><i className={"pi pi-refresh"}></i> Frissítés</a>*/}
                      </>
                      }
                      {rowData.navOnlineStatus === 'SAVED' &&
                      <>
                        <span className={"text-success"}><i className={"pi pi-check"}></i> Információk rögzítve</span>
                        {/*<a onClick={() => {*/}
                        {/*  setLoading(true)*/}
                        {/*  ReceiptNavRefreshService(rowData.id)*/}
                        {/*    .then(response => {*/}
                        {/*      setLoading(false)*/}
                        {/*      lazyParams()*/}
                        {/*    })*/}
                        {/*    .catch(error => {*/}
                        {/*      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);*/}
                        {/*      setLoading(false)*/}
                        {/*    })*/}
                        {/*}}*/}
                        {/*   className={"p-d-block p-mt-1"}><i className={"pi pi-refresh"}></i> Frissítés</a>*/}
                      </>
                      }
                      {rowData.navOnlineStatus === 'ABORTED' &&
                      <>
                        <span className={"text-danger"}><i className={"pi pi-times"}></i> Megszakítva</span>
                        {/*<a onClick={() => {*/}
                        {/*  setLoading(true)*/}
                        {/*  ReceiptNavNewService(rowData.id)*/}
                        {/*    .then(response => {*/}
                        {/*      setLoading(false)*/}
                        {/*      lazyParams()*/}
                        {/*    })*/}
                        {/*    .catch(error => {*/}
                        {/*      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);*/}
                        {/*      setLoading(false)*/}
                        {/*    })*/}
                        {/*}}*/}
                        {/*   className={"p-d-block p-mt-1"}><i className={"pi pi-plus"}></i> Új indítása</a>*/}
                      </>
                      }
                      {rowData.navOnlineStatus === 'DONE' &&
                      <>
                        <span className={"text-success"}><i className={"pi pi-check"}></i> Sikeres</span>
                      </>
                      }
                      {rowData.navOnlineStatus === 'WAITING' &&
                      <>
                        <span className={"text-warning"}><i className={"pi pi-refresh"}></i> Folyamatban</span>
                      </>
                      }
                      {rowData.navOnlineStatus === null &&
                      <>
                        {rowData.isNavInvoice === false &&
                        <>
                          <span className={"disabled"}><i className={"pi pi-times"}></i> Nem lejelentendő</span>
                        </>
                        }
                        {rowData.isNavInvoice === true &&
                        <>
                          <span><i className={"pi pi-times"}></i> Nincs adat</span>
                        </>
                        }
                      </>
                      }
                    </>
                  )
                }}
                filterElement={() => {
                  return (
                    <>
                      {lazyParams && lazyParams.filters && lazyParams.filters.navOnlineStatus ? (
                        <Dropdown options={[
                          {
                            value: "processing",
                            name: "Feldolgozása folyamatban"
                          },
                          {
                            value: "received",
                            name: "Információk átadva"
                          },
                          {
                            value: "saved",
                            name: "Információk rögzítve"
                          },
                          {
                            value: "aborted",
                            name: "Megszakítva"
                          },
                          {
                            value: "done",
                            name: "Sikeres"
                          },
                        ]}
                                  id={"navOnlineStatus"}
                                  optionValue={"value"}
                                  optionLabel={"name"}
                                  showClear
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'navOnlineStatus', '');
                                  }}
                                  value={lazyParams.filters.navOnlineStatus.value}
                        />
                      ) : (
                        <Dropdown options={[
                          {
                            value: "processing",
                            name: "Feldolgozása folyamatban"
                          },
                          {
                            value: "received",
                            name: "Információk átadva"
                          },
                          {
                            value: "saved",
                            name: "Információk rögzítve"
                          },
                          {
                            value: "aborted",
                            name: "Megszakítva"
                          },
                          {
                            value: "done",
                            name: "Sikeres"
                          },
                        ]}
                                  id={"navOnlineStatus"}
                                  optionValue={"value"}
                                  optionLabel={"name"}
                                  showClear
                                  onChange={(e) => {
                                    dt.current.filter(e.value, 'navOnlineStatus', '');
                                  }}
                        />
                      )}
                    </>
                  )
                }}
        />
        }
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-12 p-xl-12 p-text-right p-col-align-center"}>
          {props.view === 'advanced' &&
          <MultiSelect value={selectedColumnsCut} options={columnsCut} optionLabel="header"
                       onChange={onColumnToggle}
                       selectedItemsLabel="{0} oszlop kiválasztva"
                       placeholder={'Megjelenítés'}
                       className={"p-mr-2"}
          />
          }
          {selectedReceipts && selectedReceipts[0] ? (
            <>
              <Button type="button" label="Letöltés" icon="pi pi-download"
                      className="p-button-secondary p-ml-2 p-mt-2 p-mt-xl-0"
                      loading={loading}
                      onClick={() => {
                        setDownloadDialog(true)
                        // setLoading(true)
                        // axios.get(process.env.REACT_APP_API_HOST + "/receipt/download/xml?" + JSON.stringify(lazyParams).replace(',"multiSortMeta":[]', ''), {
                        //   responseType: 'blob',
                        //   params: {},
                        //   headers: {
                        //     'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
                        //     'App-Network': localStorage.getItem("networkId")
                        //   }
                        // })
                        //   .then(response => {
                        //     download(response.data, localStorage.getItem('networkName')
                        //       + '_'
                        //       + 'bizonylatok'
                        //       + '_'
                        //       + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                        //       + '.xml')
                        //     setLoading(false)
                        //   })
                        //   .catch(error => {
                        //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        //     setLoading(false)
                        //   })
                      }}
              />
              <Dialog visible={downloadDialog}
                      style={{width: '950px'}}
                      header="Bizonylatok letöltése"
                      modal
                      onHide={() => {
                        setDownloadDialog(false)
                      }}>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-12 p-xl-12 p-col-align-center"}>
                    <h3 className={"show p-mb-4"}>
                      <i className={"pi pi-file"}></i> Formátum kiválasztása
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>

                    <Dropdown
                      options={[
                        {
                          name: 'PDF',
                          value: 'pdf'
                        },
                        {
                          name: 'XML',
                          value: 'xml'
                        },
                        {
                          name: 'XLSX',
                          value: 'xlsx'
                        },
                      ]}
                      optionLabel="name"
                      optionValue="value"
                      emptyMessage={"Nincs elérhető formátum..."}
                      onChange={(e) => {
                        let _idS = []
                        Object.entries(selectedReceipts).map(function (receipt, index) {
                          _idS.push(receipt[1].id)
                        })
                        axios({
                          method: 'post',
                          url: process.env.REACT_APP_API_HOST + "/receipt/export/" + e.value,
                          data: _idS,
                          params: {},
                          headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
                        })
                          .then((response) => {
                            window.App.setState({rerenderFilequeueList: true})
                            window.App.toastShow('success', 'Sikeres művelet!', 'Export kérés rögzítve! Hamarosan letölthető lesz a kért állomány, a befejezésig türelmedet kérjük.', '');
                          })
                          .catch((error) => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);

                          })
                      }}
                    />

                  </div>
                </div>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-12 p-lg-12 p-xl-12 p-col-align-center"}>
                    <h3 className={"show"}>
                      <i className={"pi pi-upload"}></i> Export folyamatok
                    </h3>
                  </div>
                </div>
                <FilequeueListComponent/>
              </Dialog>
            </>
          ) : (
            <>
              <Button type="button" label="Letöltés" icon="pi pi-download"
                      className="p-button-secondary p-ml-2 p-mt-2 p-mt-xl-0"
                      loading={loading}
                      onClick={() => {
                        alert('Legalább egy számla kijelölése szükséges!')
                      }}
              />
            </>
          )
          }
          {selectedReceipts && selectedReceipts[0]
            //&& JSON.stringify(lazyParams).includes('allowed')
            ? (
              <>
                <Button type="button" label="Jóváhagyás visszavonása" icon="pi pi-times"
                        className="p-button-danger p-ml-2 p-mt-2 p-mt-xl-0"
                        onClick={() => {
                          setLoading(true)
                          let ids = []
                          Object.entries(selectedReceipts).map(function (receipt, index) {
                            ids.push(receipt[1].id)
                          })
                          let data = {
                            method: 'allowed',
                            value: false,
                            receipts: ids
                          }
                          ReceiptBatchService(data)
                            .then(response => {
                              ReceiptListService(JSON.stringify(lazyParams))
                                .then(response => {
                                  setTotalRecords(response.data.total_item_count);
                                  setReceipt(response.data.items);
                                  setLoading(false);
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  setLoading(false);
                                })
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoading(false);
                            })
                        }}
                />
                <Button type="button" label="Jóváhagyás" icon="pi pi-check"
                        className="p-button-warning p-ml-2 p-mt-2 p-mt-xl-0"
                        onClick={() => {
                          setLoading(true)
                          let ids = []
                          Object.entries(selectedReceipts).map(function (receipt, index) {
                            ids.push(receipt[1].id)
                          })
                          let data = {
                            method: 'allowed',
                            value: true,
                            receipts: ids
                          }
                          ReceiptBatchService(data)
                            .then(response => {
                              ReceiptListService(JSON.stringify(lazyParams))
                                .then(response => {
                                  setTotalRecords(response.data.total_item_count);
                                  setReceipt(response.data.items);
                                  setLoading(false);
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  setLoading(false);
                                })
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoading(false);
                            })
                        }}
                />
              </>
            ) : (
              <>
                <Button type="button" label="Jóváhagyás visszavonása" icon="pi pi-times"
                        className="p-button-danger p-ml-2 p-mt-2 p-mt-xl-0"
                        onClick={() => {
                          alert('Legalább egy számla kijelölése szükséges!')
                        }}
                />
                <Button type="button" label="Kijelöltek jóváhagyása" icon="pi pi-check"
                        className="p-button-warning p-ml-2 p-mt-2 p-mt-xl-0"
                        onClick={() => {
                          alert('Legalább egy számla kijelölése szükséges!')
                        }}
                />
              </>
            )
          }
          {selectedReceipts && selectedReceipts[0]
            // && JSON.stringify(lazyParams).includes('"isPaid":{"value":true')
            ? (
              <>
                <Button type="button" label="Fizetve visszavonása" icon="pi pi-times"
                        className="p-button-danger p-ml-2 p-mt-2 p-mt-xl-0"
                        onClick={() => {
                          setLoading(true)
                          let ids = []
                          Object.entries(selectedReceipts).map(function (receipt, index) {
                            ids.push(receipt[1].id)
                          })
                          let data = {
                            method: 'isPaid',
                            value: false,
                            receipts: ids
                          }
                          ReceiptBatchService(data)
                            .then(response => {
                              ReceiptListService(JSON.stringify(lazyParams))
                                .then(response => {
                                  setTotalRecords(response.data.total_item_count);
                                  setReceipt(response.data.items);
                                  setLoading(false);
                                  setSelectedReceipts([])
                                  window.App.setState({rerenderReceiptList: true})
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  setLoading(false);
                                })
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoading(false);
                            })
                        }}
                />
              </>
            ) : (
              <>
                <Button type="button" label="Fizetve visszavonása" icon="pi pi-times"
                        className="p-button-danger p-ml-2 p-mt-2 p-mt-xl-0"
                        onClick={() => {
                          alert('Legalább egy számla kijelölése szükséges!')
                        }}
                />
              </>
            )
          }
          {selectedReceipts && selectedReceipts[0]
            // && JSON.stringify(lazyParams).includes('"isPaid":{"value":false')
            ? (
              <>
                <div className="p-inputgroup p-d-inline p-mt-2 p-mt-xl-0">
                  <InputMask id={"validUntil"}
                             mask={"9999-99-99"}
                             placeholder={"ÉÉÉÉ-HH-NN"}
                             value={selectedDate}
                             onChange={(e) => {
                               if (e.value.indexOf("_") === -1) {
                                 if (e.value === '') {
                                   e.value = null
                                 }
                                 setSelectedDate(e.value)
                               }
                             }}
                             className={"p-ml-2"}
                             style={{width: '160px'}}
                  />
                  {selectedDate === undefined || selectedDate === null || selectedDate === '' || selectedDate.toString().includes('_') ? (
                    <Button type="button" label="Fizetve" icon="pi pi-check"
                            className="p-button-warning p-mt-2 p-mt-xl-0"
                            onClick={() => alert('Fizetve dátum választása kötelező!')}
                    />
                  ) : (
                    <Button type="button" label="Fizetve" icon="pi pi-check"
                            className="p-button-warning p-mt-2 p-mt-xl-0"
                            onClick={() => {
                              setLoading(true)
                              let ids = []
                              Object.entries(selectedReceipts).map(function (receipt, index) {
                                ids.push(receipt[1].id)
                              })
                              let data = {
                                method: 'isPaid',
                                value: selectedDate,
                                receipts: ids
                              }
                              ReceiptBatchService(data)
                                .then(response => {
                                  ReceiptListService(JSON.stringify(lazyParams))
                                    .then(response => {
                                      setTotalRecords(response.data.total_item_count);
                                      setReceipt(response.data.items);
                                      setLoading(false);
                                      setSelectedReceipts([])
                                      window.App.setState({rerenderReceiptList: true})
                                    })
                                    .catch(error => {
                                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      setLoading(false);
                                    })
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  setLoading(false);
                                })
                            }}
                    />
                  )}
                </div>
              </>
            ) : (
              <Button type="button" label="Fizetve" icon="pi pi-check"
                      className="p-button-warning p-ml-2 p-mt-2 p-mt-xl-0"
                      onClick={() => {
                        alert('Legalább egy számla kijelölése szükséges!')
                      }}
              />
            )
          }
        </div>
      </div>
      {props.view === 'advanced' &&
      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12 p-lg-8"}>

        </div>
        <div className={"p-col-12 p-lg-4"}>
          {sum && sum[0] &&
          <>
            <hr className={"p-mb-3"}/>
            {sum.map((item, index) => {
              let currency
              if (item[0].currency === 'HUF') {
                currency = {
                  precision: 2,
                  symbol: '',
                  decimal: ',',
                  thousand: ' ',
                }
              } else {
                currency = {
                  precision: 2,
                  symbol: '',
                  decimal: '.',
                  thousand: ' ',
                }
              }
              return (
                <>
                  <div className={"p-grid"}>
                    <div className={"p-col p-text-right"}>
                      Nettó összesen:
                    </div>
                    <div className={"p-col p-text-right"}>
                      {currencyFormatter.format(
                        item[0].sumNet, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{item[0].currency}
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col p-text-right"}>
                      ÁFA összesen:
                    </div>
                    <div className={"p-col p-text-right"}>
                      {currencyFormatter.format(
                        item[0].sumVat, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{item[0].currency}
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col p-text-right"}>
                      Bruttó összesen:
                    </div>
                    <div className={"p-col p-text-right"}>
                      {currencyFormatter.format(
                        item[0].sumBr, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{item[0].currency}
                    </div>
                  </div>
                  <hr/>
                </>
              )
            })}
          </>
          }
        </div>
      </div>
      }
      <Dialog visible={window.App.state.dialogReceiptDeliverynoteInvoiceCreate}
              style={{width: '750px'}}
              header="Költségszámla készítése"
              modal
              onHide={() => {
                window.App.setState({dialogReceiptDeliverynoteInvoiceCreate: false})
              }}>
        <ReceiptDeliverynoteInvoiceComponent/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(ReceiptListComponent);
