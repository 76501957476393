import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import CosttypeFormService from '../../services/costtype/CosttypeFormService'

const CosttypeFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      CosttypeFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderCosttypeList: true,
            sidebarCosttypeUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      CosttypeFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerender: true,
            rerenderCosttypeList: true,
            sidebarCosttypeCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    if (props && props.initialValues) {
      setFormData(
        props.initialValues
      )
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="acronym" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="acronym"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Rövidítés</label>
                  <span className="p-d-block">
                      <InputText id="acronym" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     acronym: e.target.value
                                   })
                                 }}
                                 value={formData.acronym}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(CosttypeFormComponent);
