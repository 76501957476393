import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import UnitListService from "../../services/unit/UnitListService";
import UnitDeleteService from "../../services/unit/UnitDeleteService";

const UnitListComponent = (props) => {

  const [unit, setUnit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(Number(process.env.REACT_APP_LIST_ROW_DEFAULT));
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderUnitList: false})
      UnitListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setUnit(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: Number(process.env.REACT_APP_LIST_ROW_OP1), value: Number(process.env.REACT_APP_LIST_ROW_OP1)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP2), value: Number(process.env.REACT_APP_LIST_ROW_OP2)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP3), value: Number(process.env.REACT_APP_LIST_ROW_OP3)},
        {label: Number(process.env.REACT_APP_LIST_ROW_OP4), value: Number(process.env.REACT_APP_LIST_ROW_OP4)},
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteUnit = (rowData) => {
    UnitDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_unit_write') &&
          rowData.code === 'own' ? (
          <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                  tooltip="Módosítás"
                  onClick={() => {
                    window.App.setState({
                      sidebarUnitUpdate: true,
                      props: rowData
                    })
                  }}/>
        ):(
          <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2 disabled"
                  tooltip="Módosítás" disabled
          />
        )}
        {/*{localStorage.getItem('roles').includes('role_unit_write') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteUnit(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderUnitList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={unit} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
responsiveLayout="scroll"
      stripedRows
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
    </DataTable>

  )
}
export default withTranslation('common')(UnitListComponent);
