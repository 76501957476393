import React, {useState, useEffect} from 'react';
import axios from "axios";
import {withTranslation} from 'react-i18next';
import moment from "moment";
import {Chart} from 'primereact/chart';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {InputMask} from "primereact/inputmask";

const TracklogChartComponent = (props) => {

  const {t} = props;
  const [tracklogs, setTracklogs] = useState([]);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterCreatedAtFrom, setFilterCreatedAtFrom] = useState(moment().subtract(7,'d').format('YYYY-MM-DD'));
  const [filterCreatedAtTo, setFilterCreatedAtTo] = useState(moment(new Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    setLoading(true)
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/track_log/" + props.metricsId + "/visualization?"+ JSON.stringify(
        {
          filters: {
            createdAtFrom: {
              value: filterCreatedAtFrom
            },
            createdAtUntil: {
              value: filterCreatedAtTo
            },
          }
        }
      ),
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setTracklogs(response.data.items);
        setLoading(false);
        let _labels = []
        response.data.items.map((log, index) => {
          _labels.push(
            moment.utc(log.createdAt).local().format('YYYY-MM-DD HH:mm')
          )
        })
        setLabels(_labels)
      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [filterCreatedAtFrom, filterCreatedAtTo])

  useEffect(() => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/metrics/" + props.metricsId,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        let _datasets = []
        response.data.measures.map((measure, index) => {
          var randomColor = Math.floor(Math.random()*16777215).toString(16);
          let _data = []
          tracklogs.map((tracklog, index) => {
            let value = tracklog.measures.filter(item => item.id === measure.id)
            if (value && value[0]) {
              let _value = value[0].value
              _data.push(_value)
            } else {
              _data.push(0)
            }
          })
          let _hidden = true
          if(index === 0) {
            _hidden = false
          }
          _datasets.push(
            {
              label: measure.name,
              data: _data,
              fill: false,
              borderColor: '#' + randomColor,
              tension: 0.4,
              hidden: false
            }
          )
        })
        setDatasets(_datasets)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [tracklogs])

  return (
    <>
      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-10 p-xl-10 p-col-align-center p-pt-0"}>

        </div>
        <div className={"p-col-12 p-lg-2 p-xl-2 p-text-right p-col-align-center p-pt-0"}>

          <div className={"p-grid"}>
            <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"createdAtFrom"}
                               className={"w-100"}
                               value={filterCreatedAtFrom}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterCreatedAtFrom(e.value)
                                 }
                               }}
                    />
                  </span>
            </div>
            <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
              <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
            </div>
            <div className={"p-col-5"}>
                  <span className={"p-d-block"}>
                    <InputMask id={"createdAtTo"}
                               className={"w-100"}
                               value={filterCreatedAtTo}
                               mask={"9999-99-99"}
                               placeholder={"ÉÉÉÉ-HH-NN"}
                               onChange={(e) => {
                                 if (e.value.indexOf("_") === -1) {
                                   if (e.value === '') {
                                     e.value = null
                                   }
                                   setFilterCreatedAtTo(e.value)
                                 }
                               }}
                    />
                  </span>
            </div>
          </div>


        </div>
      </div>
      <Chart type="line"
             data={
               {
                 labels: labels,
                 datasets: datasets
               }
             }
             options={
               {
                 responsive: true,
                 maintainAspectRatio: false,
                 aspectRatio: 0.5,
                 plugins: {
                   legend: {
                     display: true,
                     labels: {
                       color: '#000',
                     }
                   },
                 },
                 scales: {
                   x: {
                     ticks: {
                       color: '#000'
                     },
                     grid: {
                       color: '#fff'
                     }
                   },
                   y: {
                     ticks: {
                       color: '#000'
                     },
                     grid: {
                       color: '#fff'
                     }
                   }
                 }
               }
             }/>
    </>
  )
}
export default withTranslation('common')(TracklogChartComponent);
