import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import TechnologyFormService from '../../services/technology/TechnologyFormService'
import {InputSwitch} from "primereact/inputswitch";
import axios from "axios";
import CompanyListService from "../../services/company/CompanyListService";
import {AutoComplete} from "primereact/autocomplete";

const TechnologyFormComponent = (props) => {

  const [formData, setFormData] = useState({
    active: true
  });
  const [workflow, setWorkflow] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((props && props.initialValues) || formData.id) {
      TechnologyFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderTechnologyList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarTechnologyCreate: false,
              sidebarTechnologyUpdate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      TechnologyFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData(response.data)
          window.App.setState({rerenderTechnologyList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarTechnologyCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // workflow autocomplete
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');

  const searchWorkflow = (event) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_HOST + "/workflow?" + JSON.stringify({filters: {name: {value: event.query}}}),
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setWorkflows(response.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (props && props.initialValues) {
      setFormData(props.initialValues)
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={"w-100"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-d-block">
                        <InputText id="name" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       name: e.target.value
                                     })
                                   }}
                                   value={formData.name}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="workflow" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="workflow"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkafolyamat</label>
                  <span className="p-d-block">
                        <AutoComplete value={selectedWorkflow}
                                      id={"workflow"}
                                      suggestions={workflows}
                                      forceSelection
                                      completeMethod={searchWorkflow}
                                      field={"name"}
                                      delay="1000"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          workflow: {id: e.value.id}
                                        })
                                      }}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          workflow: {id: null}
                                        })
                                        setSelectedWorkflow(e.value)
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedWorkflow('')
                                      }}
                        />
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Field name="active" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="active"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={formData.active}
                          value={formData.active}
                          onChange={e => setFormData({...formData, active: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-5"}>
              <Field name="subcontractor" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="subcontractor"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Alvállalkozó által végzett technológia</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={formData.subcontractor}
                          value={formData.subcontractor}
                          onChange={e => setFormData({...formData, subcontractor: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-5"}>
              <Field name="schedule" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="schedule"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Műszak beosztásban megjelenik</label>
                  <span className="p-d-block">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={formData.schedule}
                          value={formData.schedule}
                          onChange={e => setFormData({...formData, schedule: e.target.value})}/>
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-4 p-xl-4"}>

            </div>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(TechnologyFormComponent);
