import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, Form} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";

import EmployeeListService from "../../services/employee/EmployeeListService";
import {AutoComplete} from "primereact/autocomplete";
import axios from "axios";

const ProductionitemWorkitemFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_HOST + "/production_item/" + window.App.state.propsProductionitem.id + "/work_item",
      data,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("userToken"),
        'App-Network': localStorage.getItem("networkId")
      }
    })
      .then(response => {
        setLoading(false);
        window.App.setState({
          dialogProductionitemWorkitem: false,
        })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // employee autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();

  const searchEmployee = (event) => {
    EmployeeListService(JSON.stringify({
      filters: {
        name: {
          value: event.query
        }
      }
    }))
      .then(response => {
        setEmployees(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-12"}>
              <Field name="contact" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="contact"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>
                  <span className={"p-d-block"}>
                    <AutoComplete value={selectedEmployee}
                                  id={"contact"}
                                  suggestions={employees}
                                  forceSelection
                                  completeMethod={searchEmployee}
                                  field={"name"}
                                  delay="1000"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      employee: {id: e.value.id},
                                    })
                                  }}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      employee: {id: null},
                                    })
                                    setSelectedEmployee(e.value)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedEmployee('')
                                  }}
                    />
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>

          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProductionitemWorkitemFormComponent);
